import { FC, useState } from 'react';
import { Button, Modal } from 'antd';
import CPPageTemplate from '../../../../components/Layout/Common/CPPageTemplate';
import FileBankForm from '../../../../components/FileBank/Form';
import List from '../../../../components/FileBank/List';

const FileBankList: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const createHandler = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setUpdateTrigger(Date.now());
    setIsOpen(false);
  };

  return (
    <CPPageTemplate
      title={'File Bank'}
      extra={<Button onClick={createHandler}>CREATE</Button>}
    >
      <List updateTrigger={updateTrigger} />

      <Modal
        title={'Upload new file'}
        open={isOpen}
        footer={[
          <Button key='cancel' onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        onCancel={handleCancel}
        width={1000}
      >
        <FileBankForm />
      </Modal>
    </CPPageTemplate>
  );
};

export default FileBankList;
