// import { Permissions } from 'constants/users/permissions';
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import React from "react";

import AuthService from "../../../../../services/auth.service";
import { UserRoleTypes } from "../../../../../services/enums";
import {
  getLink,
  getTitleWithBadge,
} from "../../../../../services/menuCreating";
import { ProfileMenuNav } from "./navigation";

const getMenuItems = (
  userName: string,
  userRole: UserRoleTypes,
  navigate: any,
  setIsAuth: any,
  messageCount: number = 0
): ItemType[] => {
  return [
    {
      key: "profile-submenu",
      icon: React.createElement(UserOutlined),
      className: "ant-menu-item",
      label: getLink(
        getTitleWithBadge(userName, messageCount),
        ProfileMenuNav.PROFILE
      ),
      children: [
        {
          key: "profile-submenu-user",
          icon: React.createElement(UserOutlined),
          label: getLink("Profile", ProfileMenuNav.PROFILE),
        },
        [UserRoleTypes.Admin].includes(userRole)
          ? {
              key: "profile-submenu-control-panel",
              icon: React.createElement(SettingOutlined),
              label: getLink("Control Panel", ProfileMenuNav.CONTROL_PANEL),
            }
          : null,
        {
          key: "profile-submenu-logout",
          icon: React.createElement(LogoutOutlined),
          label: "Logout",
          onClick: () => AuthService.logout(setIsAuth, navigate),
        },
      ],
    },
  ];
};

export default getMenuItems;
