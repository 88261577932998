import React, { FC, useState } from 'react';
import { Form, Button, notification, Modal } from 'antd';

import CommonService from '../../services/common.service';
import { formItemLayout } from '../../services/constants';
import MagicPagesService from '../../services/MagicPages/magicPages.service';
import { IMagicPageHistory } from '../../models/magic-page-history.model';

interface IProps {
  data: IMagicPageHistory;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const MagicPageHistoryFormModal: FC<IProps> = ({ data, isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(false);

  const restore = async () => {
    setLoading(true);

    try {
      await MagicPagesService.restore(data?.id);

      notification.success({ message: `${data.title} has been restored` });

      setIsOpen(false);
      setLoading(false);
    } catch (errors: any) {
      setLoading(false);

      CommonService.showAllErrors(errors);
    }
  };

  const handleSubmitForm = async () => {
    await restore();
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      title={data?.title}
      open={isOpen}
      footer={[
        <Button key='cancel' onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
      onCancel={handleCancel}
      width={1000}
    >
      <Form {...formItemLayout} onFinish={handleSubmitForm}>
        <Form.Item label='Title'>{data.title}</Form.Item>
        <Form.Item label='Seo title'>{data.seoTitle}</Form.Item>
        <Form.Item label='Description'>{data.description}</Form.Item>
        <Form.Item label='Seo description'>{data.seoDescription}</Form.Item>
        <Form.Item label='Url'>{data.url}</Form.Item>
        <Form.Item label='Content'>{data.content}</Form.Item>
        <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
          <Button type='primary' htmlType='submit' loading={loading}>
            Restore
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MagicPageHistoryFormModal;
