import {
  BellOutlined,
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  List,
  Popover,
  Space,
  Typography,
  notification
} from 'antd';
import { FC, useState } from 'react';
import useHttpGet from '../../../../hooks/useHttpGet';
import { APIRoutes } from '../../../../http/apiRoutes';
import { INotification } from '../../../../models/Notifications/notification.model';
import { IResultResponse } from '../../../../models/response/result.model';
import NotificationsService from '../../../../services/Notifications/notifications.service';
import CommonService from '../../../../services/common.service';
import { NotificationTypes } from '../../../../services/enums';
import classes from './Notifications.module.css';

const Notifications: FC = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [data, setData] = useState<INotification[]>([]);

  const { fetchedData: fetchedNotifications } = useHttpGet<
    IResultResponse<INotification[]>
  >(APIRoutes.NOTIFICATIONS, {
    query: {
      count: 100,
    },
    defaultValue: {} as IResultResponse<INotification[]>,
    resolve: (response) => {
      setData(response.result);
    },
    reject: (errors) => {
      CommonService.showAllErrors(errors);
    },
  });

  useHttpGet<IResultResponse<number>>(APIRoutes.NOTIFICATIONS_COUNT_UNREAD, {
    dependencies: [fetchedNotifications],
    defaultValue: 0,
    reject: (errors) => {
      CommonService.showAllErrors(errors);
    },
    resolve: (response) => setUnreadCount(response.result),
  });

  const getIcon = (iconType: NotificationTypes) => {
    switch (iconType) {
      case NotificationTypes.Success:
        return <CheckCircleOutlined />;
      case NotificationTypes.Error:
        return <CloseCircleFilled style={{ color: 'red' }} />;
      case NotificationTypes.Warning:
        return <WarningOutlined />;
    }
    return <InfoCircleOutlined />;
  };

  const handlerReadAll = () => {
    NotificationsService.read(data.map((item) => item.id))
      .then(() =>
        setData(
          data.map((item) => {
            return { ...item, isRead: true };
          })
        )
      )
      .then(() => notification.success({ message: `The messages has read` }))
      .catch((error) =>
        notification.error({ message: `The messages hasn't read: ${error}` })
      );
  };

  const handlerArchiveAll = () => {
    NotificationsService.archive(data.map((item) => item.id))
      .then(() => setData([]))
      .then(() =>
        notification.success({ message: `The messages has archived` })
      )
      .catch((error) =>
        notification.error({
          message: `The messages hasn't archived: ${error}`,
        })
      );
  };

  const list = () => {
    return (
      <>
        <div
          style={{
            maxHeight: '50vh',
            width: '25vw',
            overflowY: 'auto',
            background: 'white',
          }}
        >
          <List
            dataSource={data}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={[
                  <Button
                    key={`list-delete-item-${item.id}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      NotificationsService.archive([item.id])
                        .then(() =>
                          setData(data.filter((i) => i.id !== item.id))
                        )
                        .catch((error) =>
                          notification.error({
                            message: `The messages hasn't achived: ${error}`,
                          })
                        );
                    }}
                  >
                    <CloseOutlined />
                  </Button>,
                ]}
                className={`${classes.notificationItem} ${item.isRead
                  ? classes.notificationItemRead
                  : classes.notificationItemUnread
                  }`}
                onClick={() => {
                  if (!item.isRead) {
                    NotificationsService.read([item.id])
                      .then(() =>
                        setData(
                          data.map((i) =>
                            i.id === item.id ? { ...i, isRead: true } : i
                          )
                        )
                      )
                      .catch((error) =>
                        notification.error({
                          message: `The messages hasn't read: ${error}`,
                        })
                      );
                  }
                }}
              >
                <List.Item.Meta
                  avatar={getIcon(item.type)}
                  title={item.message}
                  description={CommonService.getPassedTime(item.createdOnUtc)}
                />
                <div></div>
              </List.Item>
            )}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <Space size='large'>
            <Typography.Link onClick={handlerReadAll}>read all</Typography.Link>
            <Typography.Link onClick={handlerArchiveAll}>
              clear all
            </Typography.Link>
            {/* <Typography.Link
              onClick={() => NotificationsService.create(
                {
                  type: NotificationTypes.Info,
                  event: 1,
                  message: 'string',
                } as INotification
              )}
            >
              add
            </Typography.Link> */}
          </Space>
        </div>
      </>
    );
  };

  return (
    <Popover placement='bottom' content={list}>
      <div className='ant-menu ant-menu-root ant-menu-horizontal ant-menu-light'>
        <div className='ant-menu-submenu ant-menu-submenu-horizontal'>
          <BellOutlined />
          <Badge count={unreadCount} size='small' offset={[0, -15]} />
        </div>
      </div>
    </Popover>
  );
};

export default Notifications;
