import React, { FC, useState } from "react";
import { Col, Row, Spin } from "antd";
import { Link } from "react-router-dom";
import { MenuItem } from "../AppLayout/components/Footer/Footer";
import { UIRoutes } from "../../../router";
import { IMagicPage } from "../../../models/MagicPages/magicPage.model";
import { MagicPagesTypes, SettingsTypes } from "../../../services/enums";
import { APIRoutes } from "../../../http/apiRoutes";
import CommonService from "../../../services/common.service";
import { ISystemSettings } from "../../../models/SystemSettings/systemSetting.model";
import useHttpGet from "../../../hooks/useHttpGet";
import { IResultResponse } from "../../../models/response/result.model";

interface IProps {
  data: IMagicPage;
  loading: boolean;
  children: React.ReactNode;
}

const AuthTemplate: FC<IProps> = ({ data, loading, children }) => {
  const [settings, setSettings] = useState({
    headerSettings: {
      logoUrl: "",
      logoTitle: "",
    },
  });

  useHttpGet<IResultResponse<ISystemSettings[]>>(
    `${APIRoutes.SYSTEM_SETTINGS}/public`,
    {
      defaultValue: { result: [] as ISystemSettings[] },
      resolve: (response) => {
        setSettings({
          headerSettings: {
            logoUrl: `${APIRoutes.API_BASE}/${
              response.result.filter(
                (i: any) => i.id === SettingsTypes.LogoUrl
              )[0].value
            }`,
            logoTitle: response.result.filter(
              (i: any) => i.id === SettingsTypes.LogoTitle
            )[0].value,
          },
        });
      },
      reject: CommonService.showAllErrors,
    }
  );

  const links: MenuItem[] = [
    {
      name: "Terms of Use",
      segment: "terms-conditions",
      id: MagicPagesTypes.Terms,
    },
    {
      name: "Privacy Policy",
      segment: "privacy-policy",
      id: MagicPagesTypes.PrivacyPolicy,
    },
    {
      name: "Contact Us",
      segment: "contact",
      id: MagicPagesTypes.Contacts,
    },
  ];

  return (
    <div className="main-login main-login--fullscreen">
      <div className="main-login__header">
        <Row>
          <Col xs={14} sm={13} md={12}>
            <div className="main-login__header__logo">
              <div className="logo">
                <a href="/">
                  <img
                    src={settings.headerSettings.logoUrl}
                    alt={settings.headerSettings.logoTitle}
                    title={settings.headerSettings.logoTitle}
                  />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="main-login__block main-login__block--extended pb-0 mt-2">
        <Row>
          <Col xs={24} sm={24} md={24}>
            <div className="main-login__block__inner">
              <h4 className="text-uppercase fs-22 mb-4">
                <strong>{data?.title}</strong>
              </h4>

              <div className="main-login__block__form">{children}</div>

              <div className="main-login__block__sidebar d-flex justify-content-center align-items-center text-gray h-100">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.content,
                  }}
                  className="pl-1 pt-0_5"
                />

                {loading && <Spin size="large" />}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="main-login__footer text-center">
        <ul className="list-unstyled list-inline d-flex flex-wrap w-100 align-items-center justify-content-center">
          {links.map((el: MenuItem) => (
            <li className="list-inline-item" key={el?.id}>
              <Link
                to={
                  el.url
                    ? el.url
                    : `/${UIRoutes.PAGE_BASE_VIEW}/${el.segment}/${el.id}`
                }
              >
                {el.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AuthTemplate;
