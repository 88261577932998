import React, { FC, useState } from 'react';
import { Form, Input, Button, notification, Alert } from 'antd';
import { Link } from 'react-router-dom';

import AuthService from '../../services/auth.service';
import { UIRoutes } from '../../router';

const ForgotPasswordForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    try {
      await AuthService.forgotPasswordRequest(values?.email);

      notification.success({
        message:
          'We just sent you an email with instructions to reset your password',
      });

      setLoading(false);
      setError('');
    } catch (errors: any) {
      setLoading(false);

      setError(errors?.response?.data?.errorMessages[0] || '');
    }
  };

  const errorMessage = !!error ? (
    <Alert message={error} type='error' className='mb-1' />
  ) : null;

  return (
    <>
      {errorMessage}
      <Form
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSubmitForm}
      >
        <Form.Item
          label='Email'
          name='email'
          rules={[
            { required: true, message: 'Please input email!' },
            { type: 'email', message: 'Invalid email!' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <div className='mb-2'>
            <Link
              to={`/${UIRoutes.ACCOUNT}`}
              className='utils__link--blue utils__link--underlined'
            >
              Already have an account? Please log in
            </Link>
          </div>
          <Button type='primary' htmlType='submit' loading={loading}>
            Send
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ForgotPasswordForm;
