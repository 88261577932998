import { AxiosResponse } from 'axios';
import RequestsService from '../requests.service';
import { APIRoutes } from '../../http/apiRoutes';
import { IResultResponse } from '../../models/response/result.model';
import { IMagicPage } from '../../models/MagicPages/magicPage.model';
import { IMagicPageHistory } from '../../models/magic-page-history.model';

export default class MagicPagesService {
  static async create(
    magicPage: IMagicPage
  ): Promise<AxiosResponse<IResultResponse<IMagicPage>>> {
    return RequestsService.postMethod<IResultResponse<IMagicPage>>(
      `${APIRoutes.MAGIC_PAGES}`,
      magicPage
    );
  }

  static async update(
    magicPage: IMagicPage
  ): Promise<AxiosResponse<IResultResponse<IMagicPage>>> {
    return RequestsService.putMethod<IResultResponse<IMagicPage>>(
      `${APIRoutes.MAGIC_PAGES}`,
      magicPage
    );
  }

  static async restore(
    magicPageId: string
  ): Promise<AxiosResponse<IResultResponse<IMagicPageHistory>>> {
    return RequestsService.putMethod<IResultResponse<IMagicPageHistory>>(
      `${APIRoutes.MAGIC_PAGES_HISTORY}/restore/${magicPageId}`,
      null
    );
  }

  static async delete(
    magicPageId: string
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.deleteMethod<IResultResponse<any>>(
      `${APIRoutes.MAGIC_PAGES}/${magicPageId}`,
      null
    );
  }
}
