import { Image, Layout, Menu, MenuProps } from "antd";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import getLeftMenuData from "../../../areas/controlPanel/config/menuItemsData";
import { APIRoutesBase } from "../../../http/apiRoutes";
import {
  ISystemSettings,
  getValue,
} from "../../../models/SystemSettings/systemSetting.model";
import { SettingsTypes } from "../../../services/enums";

interface IProps {
  settings: ISystemSettings[];
}

export const Sider: FC<IProps> = ({ settings }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [openKeys, setOpenKeys] = useState([""]);
  const [keys, setKeys] = useState<string[]>(
    [`${localStorage.getItem("app.menu.selectedKeys")}`] || []
  );

  const location = useLocation();
  const menu = getLeftMenuData();

  const rootSubmenuKeys = ["182", "186", "187"];

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys([...keys]);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey, "18"] : []);
    }
  };

  const handleClick = (e: any) => {
    localStorage.setItem("app.menu.selectedKeys", e.key);
    setKeys([e.key]);
  };

  useEffect(() => {
    setSelectedKeys();
  }, [collapsed]);

  const setSelectedKeys = () => {
    const flattenItems = (items: any, key: any) =>
      items.reduce((flattenedItems: any, item: any) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []);

    const selectedItem = _.find(flattenItems(menu, "children"), [
      "url",
      location.pathname,
    ]);

    setKeys(selectedItem ? [selectedItem.id.toString()] : []);
  };

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      style={{ maxHeight: "calc(100vh - 48px)", overflowY: "scroll" }}
      width="250"
    >
      <div className="side-logo">
        <Link to={`/`} title={getValue(settings, SettingsTypes.LogoTitle)}>
          <Image
            src={
              settings?.length > 0
                ? `${APIRoutesBase.API_BASE}/${getValue(
                    settings,
                    SettingsTypes.LogoUrl
                  )}`
                : ""
            }
            alt={getValue(settings, SettingsTypes.LogoTitle)}
            preview={false}
          />
        </Link>
      </div>

      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={keys}
        onClick={handleClick}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        items={menu}
      />
    </Layout.Sider>
  );
};

export default Sider;
