import { AxiosResponse } from "axios";
import RequestsService from "../requests.service";
import { APIRoutes } from "../../http/apiRoutes";
import { IResultResponse } from "../../models/response/result.model";
import { IMagicPageItem } from "../../models/MagicPages/magicPage.model";

export default class MagicPagesContentItems {
  static async getForPage(
    pageId: string
  ): Promise<AxiosResponse<IResultResponse<IMagicPageItem>>> {
    return RequestsService.getMethod<IResultResponse<IMagicPageItem>>(
      `${APIRoutes.MAGIC_PAGES_Content_Items_ForPage}/${pageId}`
    );
  }

  static async create(
    magicPage: IMagicPageItem
  ): Promise<AxiosResponse<IResultResponse<IMagicPageItem>>> {
    return RequestsService.postMethod<IResultResponse<IMagicPageItem>>(
      `${APIRoutes.MAGIC_PAGES_Content_Items}`,
      magicPage
    );
  }

  static async update(
    magicPage: IMagicPageItem
  ): Promise<AxiosResponse<IResultResponse<IMagicPageItem>>> {
    return RequestsService.putMethod<IResultResponse<IMagicPageItem>>(
      `${APIRoutes.MAGIC_PAGES_Content_Items}`,
      magicPage
    );
  }

  static async delete(
    magicPageId: string
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.deleteMethod<IResultResponse<any>>(
      `${APIRoutes.MAGIC_PAGES}/${magicPageId}`,
      null
    );
  }
}
