import { Menu } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import getMenuItems from "./config/menuItemsData";

const ProfileMenu: FC = () => {
  const navigate = useNavigate();
  const { setIsAuth } = useActions();
  const currentUser = useTypedSelector((state) => state.auth.currentUser);

  return (
    <Menu
      theme="light"
      mode="horizontal"
      selectable={false}
      disabledOverflow
      items={getMenuItems(
        currentUser.firstName,
        currentUser.role,
        navigate,
        setIsAuth
      )}
    />
  );
};

export default ProfileMenu;
