import { notification } from 'antd';

export default class CommonService {
  static toLocalNum(number: number, options: any = {}): string {
    try {
      return number?.toLocaleString('en', {
        maximumFractionDigits: 3,
        ...options,
      });
    } catch (err) {
      return '0';
    }
  }

  static getProperty(data: any, property: string) {
    if (data.hasOwnProperty(property)) {
      return data[property];
    }
    return 'N/A';
  }

  static sortByDate(array: any[] = [], parram = 'date', sortAsc = true) {
    try {
      if (sortAsc) {
        return array.sort(
          (a, b) =>
            new Date(a[parram]).getTime() - new Date(b[parram]).getTime()
        );
      } else {
        return array.sort(
          (a, b) =>
            new Date(b[parram]).getTime() - new Date(a[parram]).getTime()
        );
      }
    } catch (err) {
      return array;
    }
  }

  static groupBy(array: any[] = [], key: any) {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  }

  static numberColor = (number: number) => {
    if (number < 0) {
      return 'number-minus';
    } else if (number === 0) {
      return 'number-zero';
    } else {
      return 'number-plus';
    }
  };

  static replaceSymbol(name: string): string {
    try {
      return name
        .trim()
        .replaceAll(' ', '-')
        .replace(/[&\/\\#,+()$~%.'':*?<>{}‘’]/g, '')
        .toLocaleLowerCase();
    } catch (err) {
      return name;
    }
  }

  static getNameValues(enumType: {
    [s: number]: string;
  }): Array<{ value: number; name: string }> {
    return Object.keys(enumType)
      .filter((key) => !isNaN(parseInt(key)))
      .map((key) => {
        const val = parseInt(key);
        const name = enumType[val];
        return {
          value: val,
          name: name,
        };
      });
  }

  static showAllErrors(errors: any) {
    console.log('errors', errors);
    try {
      errors?.response?.data?.errorMessages.forEach((error: string) => {
        notification.error({ message: error });
      });
    } catch (err) {
      notification.error({ message: 'Something went wrong' });
    }
  }

  static prepareFormData = (file: File): any => {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  };

  static LightenDarkenColor(col: string, amt: number) {
    let usePound = false;
    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }
    let num = parseInt(col, 16);
    let r = (num >> 16) + amt;
    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }
    let b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }
    let g = (num & 0x0000ff) + amt;
    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }

  static getPassedTime = (startUtcDate: string): string => {
    const period = new Date(Date.now() - Date.parse(startUtcDate));

    const years = period.getUTCFullYear() - 1970;
    const months = period.getUTCMonth();
    const days = period.getUTCDate() - 1;
    const hours =
      period.getUTCHours() - Math.abs(new Date().getTimezoneOffset()) / 60;
    const minutes = period.getUTCMinutes();
    const seconds = period.getUTCSeconds();

    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""} ago`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (seconds > 0) {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
    return `just now`;
  };

  static uniqueArray = function (arrArg: any[]) {
    return arrArg.filter(function (elem: any, pos: number, arr: any[]) {
      return arr.indexOf(elem) === pos;
    });
  };

  static distinct = (arrArg: any[]) => {
    return arrArg.filter((elem: any, pos: number, arr: any[]) => {
      return arr.indexOf(elem) === pos;
    });
  };
}
