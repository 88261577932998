import { SettingsTypes } from "../../services/enums";

export interface ISystemSettings {
  note: string;
  value: string;
  id: SettingsTypes;
  name: string;
  updatedByUser: {
    id: string;
    name: string;
  };
  updatedOnUtc: string;
}

export const getValue = (array: ISystemSettings[], type: SettingsTypes) => {
  return array?.filter((f) => f.id === type)[0]?.value;
};
