// import { Permissions } from 'constants/users/permissions';
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { getLink } from "../../../../../../services/menuCreating";
import { HeaderNav } from "./navigation";

const getMenuItems = (): ItemType[] => {
  //TODO: add home & portfolio
  return [
    {
      key: "header-menu-home",
      className: "ant-menu-dark",
      label: getLink("Home", HeaderNav.Home),
    },
    {
      key: "header-menu-p",
      className: "ant-menu-dark",
      label: getLink("Projects", HeaderNav.Projects),
    },
    {
      key: "header-menu-faq",
      className: "ant-menu-dark",
      label: getLink("FAQ", HeaderNav.FAQ),
    },
    {
      key: "header-menu-about-us",
      label: getLink("About Us", HeaderNav.ABOUT_US),
    },
  ];
};

export default getMenuItems;
