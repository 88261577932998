interface IValue {
  id: number;
  name: string;
}

export const mapEnum = (enumeation: any): IValue[] => {
  const result = Object.keys(enumeation).map((key: string) => ({
    id: key,
    name: enumeation[parseInt(key)],
  }));

  return result
    .slice(0, result.length / 2)
    .map((f) => ({ id: parseInt(f.id, 10), name: stringifyEnum(f.name) }));
};

export const stringifyEnum = (enumeation: any): string => {
  return enumeation
    ?.replace("_", "")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([0-9])([A-Z])/g, "$1 $2")
    .replace(/([0-9])([a-z])/g, "$1 $2");
};

export enum NotificationTypes {
  Success = 1,
  Error = 2,
  Warning = 3,
  Info = 4,
}

export enum UserRoleTypes {
  Admin = 1,
  Slave = 2,
}

export enum RecordStates {
  Active = 0,
  Pending = 2,
  Locked = 4,
  Archived = 6,
  Deleted = 10,
}

export enum MagicPagesTypes {
  Login = 1,
  Home = 4,
  Contacts = 5,
  ContactsBlock = 51,
  Faq = 6,
  News = 4,
  RestorePassword = 5,
  WhatweDo = 7,
  Projects = 8,
  Responces = 9,
  Blog = 10,
  Career = 11,
  About = 12,
  Soft = 13,
  ImageEditing = 14,
  Seminars = 16,
  TechnologyOfUsing = 18,
  Terms = 21,
  PrivacyPolicy = 22,

  Custom = 99,
}

export enum MagicFileTypes {
  File = 1,
  Image = 2,
  Video = 3,
  CourseManual = 4,
}

export enum MagicFileDiscriminator {
  MagicFile = 1,
}

export enum SettingsTypes {
  Copyright = 1,
  HomeScreenVideo = 2,
  HomeScreenContent = 3,

  LogoTitle = 5,
  LogoUrl = 6,

  PartnerLogoUrl = 10,
  PartnerLogoTitle = 11,

  SystemEmail = 15,
  SystemEmailPassword = 16,

  ShowFaqForAll = 17,
}

export const SystemSettingsFileNames = [
  {
    name: "Home screen video",
    id: SettingsTypes.HomeScreenVideo,
  },
  {
    name: "Logo",
    id: SettingsTypes.LogoUrl,
  },
];

export enum LogLevelsTypes {
  Trace = 0,

  Debug = 1,

  Information = 2,

  Warning = 3,
  Error = 4,

  None = 5,
}
