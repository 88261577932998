import { Button, Form, FormInstance, Input, notification } from 'antd';
import { FC, useEffect, useState } from 'react';

import { IMagicPage } from '../../models/MagicPages/magicPage.model';
import MagicPagesService from '../../services/MagicPages/magicPages.service';
import CommonService from '../../services/common.service';
import { formItemLayout } from '../../services/constants';
import Editor from '../Layout/Common/Adapters/Editor';

interface IProps {
  data: IMagicPage;
  form: FormInstance;
}

const MagicPageForm: FC<IProps> = ({ data, form }) => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    setContent(data?.content);
  }, [data]);

  const create = async (values: IMagicPage) => {
    setLoading(true);

    try {
      await MagicPagesService.create({ ...values, content });

      notification.success({ message: `Page has been created` });

      setLoading(false);
    } catch (errors: any) {
      setLoading(false);

      CommonService.showAllErrors(errors);
    }
  };

  const update = async (values: IMagicPage) => {
    setLoading(true);

    try {
      await MagicPagesService.update({ ...data, ...values, content });

      notification.success({ message: `Page has been updated` });

      setLoading(false);
    } catch (errors: any) {
      setLoading(false);

      CommonService.showAllErrors(errors);
    }
  };

  const handleSubmitForm = async (values: any) => {
    if (data?.id) {
      await update(values);
    } else {
      await create(values);
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      requiredMark={true}
      onFinish={handleSubmitForm}
    >
      <Form.Item
        label='Title'
        name='title'
        rules={[{ required: true, message: 'Please input title!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Description'
        name='description'
        rules={[{ required: true, message: 'Please input description!' }]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item label='Seo title' name='seoTitle'>
        <Input />
      </Form.Item>
      <Form.Item label='Seo description' name='seoDescription'>
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item label='URL' name='url'>
        <Input />
      </Form.Item>
      <Form.Item label='Content'>
        <Editor content={content || ''} setContent={setContent} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
        <Button type='primary' htmlType='submit' loading={loading}>
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MagicPageForm;
