import React, { FC, useState } from "react";
import { Form, Alert, Input, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";

import AuthService from "../../services/auth.service";
import { useActions } from "../../hooks/useActions";
import { Areas, UIRoutes } from "../../router";
import { UserRoleTypes } from "../../services/enums";

const LoginForm: FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { setIsAuth, setCurrentUser } = useActions();
  const navigate = useNavigate();

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    try {
      const response = await AuthService.login(
        values.username,
        values.password
      );

      localStorage.setItem(
        "48bceb9d-e1a4-4c77-9d62-9b0e9748c07e",
        response.data.result.code
      );
      localStorage.setItem("app.Authorized", "true");

      setIsAuth(true);
      setCurrentUser(response.data.result.user);
      setLoading(false);
      setError("");

      switch (response.data.result.user.role) {
        case UserRoleTypes.Admin:
        case UserRoleTypes.Slave:
          navigate(`/${Areas.CONTROL_PANEL}`);
          break;
      }
    } catch (errors: any) {
      setLoading(false);
      setError(errors?.response?.data?.errorMessages[0] || "");
    }
  };

  const errorMessage = !!error ? (
    <Alert message={error} type="error" className="mb-1" />
  ) : null;

  return (
    <>
      {errorMessage}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSubmitForm}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <div className="mb-2">
            <Link
              to={`/${UIRoutes.ACCOUNT}/${UIRoutes.FORGOT_PASSWORD}`}
              className="utils__link--blue utils__link--underlined"
            >
              Forgot password
            </Link>
          </div>

          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>

          <Button
            type="link"
            onClick={() =>
              navigate(`/${UIRoutes.ACCOUNT}/${UIRoutes.REGISTER}`)
            }
            loading={loading}
          >
            Register
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginForm;
