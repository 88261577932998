import React, { FC, useRef } from 'react';
import JoditEditor from 'jodit-react';

interface IProps {
  content: string;
  setContent: (value: string) => void;
}

const Editor: FC<IProps> = ({ content, setContent }) => {
  const editor = useRef(null);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      onBlur={(value: string) => setContent(value)}
      onChange={() => {}}
    />
  );
};

export default Editor;
