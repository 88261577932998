import {
  CloseCircleOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Form, Upload, notification } from 'antd';
import { FC, useState } from 'react';

import useHttpGet from '../../hooks/useHttpGet';
import { APIRoutes } from '../../http/apiRoutes';
import { ISystemSettings, getValue } from '../../models/SystemSettings/systemSetting.model';
import { IResultResponse } from '../../models/response/result.model';
import SystemSettingsService from '../../services/SystemSettings/systemSettings.service';
import CommonService from '../../services/common.service';
import { formItemLayout } from '../../services/constants';
import {
  MagicFileTypes,
  SettingsTypes,
  SystemSettingsFileNames,
} from '../../services/enums';
import Editor from '../Layout/Common/Adapters/Editor';

const SystemSettingsHomeScreenForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const [changedHomeScreenVideo, setChangedHomeScreenVideo] = useState(null);
  const [homeScreenContent, setHomeScreenContent] = useState<any>('');

  const { Dragger } = Upload;

  const [form] = Form.useForm();

  const { fetchedData } = useHttpGet<IResultResponse<ISystemSettings[]>>(
    `${APIRoutes.SYSTEM_SETTINGS}/public`,
    {
      defaultValue: { result: [] as ISystemSettings[] },
      resolve: (response: IResultResponse<ISystemSettings[]>) => {
        form.setFieldsValue({
          homeScreenVideo: getValue(
            response.result,
            SettingsTypes.HomeScreenVideo
          ),
        });
        setHomeScreenContent(
          getValue(
            response.result, SettingsTypes.HomeScreenContent
          )
        );
      },
      reject: CommonService.showAllErrors,
    }
  );

  const createSystemSetting = (id: SettingsTypes, newValue: string) => {
    const original = fetchedData?.result?.filter(
      (rec: any) => rec.id === id
    )[0];
    original.value = newValue;
    return original;
  };

  const checkErrorHandler = (errors: any) => {
    setLoading(false);

    CommonService.showAllErrors(errors);
  };

  const uploadVideoAndUpdateSetting = async (id: SettingsTypes, file: any) => {
    if (file) {
      try {
        const formData = CommonService.prepareFormData(file);
        formData.append(
          'name',
          SystemSettingsFileNames.filter((a) => a.id === id)[0].name
        );
        formData.append('type', MagicFileTypes.Video);

        await SystemSettingsService.upload(id, formData);
      } catch (errors) {
        checkErrorHandler(errors);
      }
    }
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    uploadVideoAndUpdateSetting(
      SettingsTypes.HomeScreenVideo,
      changedHomeScreenVideo
    );

    const homeScreenContentSetting = createSystemSetting(
      SettingsTypes.HomeScreenContent,
      homeScreenContent
    );
    await SystemSettingsService.update(homeScreenContentSetting);

    setLoading(false);
    notification.success({ message: `Settings is updated` });
  };

  const handleChangeHomeScreenVideo = (info: any) => {
    setChangedHomeScreenVideo(info.file);
  };

  const uploadButton = (
    <div className='fs-22'>
      <p className='ant-upload-drag-icon'>
        {loading ? <LoadingOutlined /> : <UploadOutlined />}
      </p>
      <p className='ant-upload-text'>
        Click or drag file to this area to upload
      </p>
    </div>
  );

  const handleDraggerBeforeUpload = (file: File) => {
    if (file.size / 1024 / 1024 > 10) {
      notification.error({ message: 'Video must be smaller than 10MB!' });
    }

    return false;
  };

  return !fetchedData?.result ? null : (
    <Form
      {...formItemLayout}
      form={form}
      requiredMark={false}
      onFinish={handleSubmitForm}
    >
      <Form.Item label='Current home screen video'>
        {fetchedData?.result?.length > 0 && (
          <video
            autoPlay
            loop
            muted
            className='bgvideo'
            id='bgvideo'
            style={{ height: '250px' }}
          >
            <source
              src={`${APIRoutes.API_BASE}/${getValue(
                fetchedData.result,
                SettingsTypes.HomeScreenVideo
              )}`}
              type='video/mp4'
            />
          </video>
        )}
      </Form.Item>

      <Form.Item label='Choose new video (optional)'>
        <Dragger
          accept='video/*'
          listType='picture-card'
          showUploadList={false}
          beforeUpload={handleDraggerBeforeUpload}
          onChange={handleChangeHomeScreenVideo}
        >
          {uploadButton}
        </Dragger>

        {changedHomeScreenVideo && (
          <CloseCircleOutlined
            className='upload-close'
            onClick={() => {
              setChangedHomeScreenVideo(null);
            }}
          />
        )}
      </Form.Item>

      <Form.Item
        label='Enter home screen content (optional)'
        name='homeScreenContent'
      >
        <Editor content={homeScreenContent} setContent={setHomeScreenContent} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
        <Button type='primary' htmlType='submit' loading={loading}>
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SystemSettingsHomeScreenForm;
