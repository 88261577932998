import { Table, TablePaginationConfig } from 'antd';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { ControlPanelNav } from '../../areas/controlPanel/config/navigation';
import useHttpGet from '../../hooks/useHttpGet';
import { APIRoutes } from '../../http/apiRoutes';
import { IEmailTemplate } from '../../models/email-template.model';
import { IResultResponse } from '../../models/response/result.model';
import CommonService from '../../services/common.service';
import GetColumnSearchProps from '../Layout/Common/Adapters/TableFilter';

const List: FC = () => {
  const [filter, setFilter] = useState({
    subject: null,
    keys: null,
  });

  const { fetchedData, loading } = useHttpGet<
    IResultResponse<IEmailTemplate[]>
  >(`${APIRoutes.EMAIL_TEMPLATES}`, {
    dependencies: [filter],
    query: {
      ...filter,
    },
    defaultValue: {} as IResultResponse<IEmailTemplate[]>,
    reject: CommonService.showAllErrors,
  });

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: any,
    _: any
  ) => {
    const newFilter: any = { ...filter };

    newFilter.subject = filters.subject ? filters.subject[0] : null;
    newFilter.keys = filters.keys ? filters.keys[0] : null;

    setFilter(newFilter);
  };

  const columns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      ...GetColumnSearchProps('subject'),
    },
    {
      title: 'Keys',
      dataIndex: 'keys',
      ...GetColumnSearchProps('keys'),
    },
    {
      title: '',
      dataIndex: 'id',
      render: (_: any, record: IEmailTemplate) => {
        return (
          <Link
            to={`${ControlPanelNav.EMAIL_TEMPLATES_BASE_EDIT}/${record?.id}`}
          >
            Edit
          </Link>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={fetchedData.result || []}
      onChange={handleTableChange}
      pagination={false}
      rowKey='id'
      loading={loading}
      size='small'
    />
  );
};

export default List;
