import { Col, Row } from "antd";
import { FC } from "react";
import { Helmet } from "react-helmet";
import useHttpGet from "../../../hooks/useHttpGet";
import { APIRoutes } from "../../../http/apiRoutes";
import { IMagicPage } from "../../../models/MagicPages/magicPage.model";
import {
  ISystemSettings,
  getValue,
} from "../../../models/SystemSettings/systemSetting.model";
import { IResultResponse } from "../../../models/response/result.model";
import { REACT_APP_SITE_TITLE } from "../../../services/constants";
import { MagicPagesTypes, SettingsTypes } from "../../../services/enums";
import StandAloneForm from "../../../components/InquiryForm/StandAloneForm";

const Index: FC = () => {
  const { fetchedData } = useHttpGet<IResultResponse<IMagicPage>>(
    `${APIRoutes.MAGIC_PAGES_TYPE}/${MagicPagesTypes.Home}`,
    {
      defaultValue: { result: {} as IMagicPage },
    }
  );

  const { fetchedData: contactBlock } = useHttpGet<IResultResponse<IMagicPage>>(
    `${APIRoutes.MAGIC_PAGES_TYPE}/${MagicPagesTypes.ContactsBlock}`,
    {
      defaultValue: { result: {} as IMagicPage },
    }
  );

  const { fetchedData: homePageSettings } = useHttpGet<
    IResultResponse<ISystemSettings[]>
  >(`${APIRoutes.SYSTEM_SETTINGS}/public/`, {
    query: {
      ids: [SettingsTypes.HomeScreenVideo, SettingsTypes.HomeScreenContent],
    },
    defaultValue: { result: [] as ISystemSettings[] },
  });

  return (
    <>
      <Row justify="center" align="stretch" className="content-height">
        <Col xs={28} sm={28} md={24}>
          <Helmet>
            <title>{`${fetchedData.result?.seoTitle} | ${REACT_APP_SITE_TITLE}`}</title>
            <meta
              name="description"
              content={fetchedData.result?.seoDescription}
            />
          </Helmet>

          <div className="video h-70">
            {homePageSettings?.result?.length > 0 && (
              <video autoPlay loop muted>
                <source
                  src={`${APIRoutes.API_BASE}/${getValue(
                    homePageSettings.result,
                    SettingsTypes.HomeScreenVideo
                  )}`}
                  type="video/mp4"
                />
              </video>
            )}
            <div
              className="section-overlay pl-4"
              dangerouslySetInnerHTML={{
                __html: getValue(
                  homePageSettings.result,
                  SettingsTypes.HomeScreenContent
                ),
              }}
            ></div>
          </div>

          <div
            className="p-4 home-blocks"
            dangerouslySetInnerHTML={{
              __html: fetchedData.result?.content,
            }}
          ></div>
        </Col>
      </Row>

      <Row justify="center" align="stretch" className="contact-block">
        <Col xs={28} sm={28} md={12}>
          <div
            className="p-4"
            dangerouslySetInnerHTML={{
              __html: contactBlock.result?.content,
            }}
          ></div>
        </Col>
        <Col xs={26} sm={26} md={11}>
          <div className="p-6">
            <StandAloneForm />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Index;
