import { Col, Layout, Row } from "antd";
import { FC } from "react";
import Notifications from "../components/Notifications/Notifications";
import ProfileMenu from "../components/ProfileMenu/ProfileMenu";

const AppHeader: FC = () => {
  return (
    <Layout.Header style={{ padding: 0 }}>
      <Row>
        <Col flex={"auto"}></Col>
        <Col>
          <Notifications />
        </Col>
        <Col>
          <ProfileMenu />
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default AppHeader;
