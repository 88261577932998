import {
  Button,
  Form,
  FormInstance,
  Image,
  Input,
  Select,
  Upload,
  notification,
} from "antd";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  CloseCircleOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ControlPanelNav } from "../../areas/controlPanel/config/navigation";
import { APIRoutesBase } from "../../http/apiRoutes";
import { IUser } from "../../models/Users/user.model";
import UsersService from "../../services/Users/users.service";
import CommonService from "../../services/common.service";
import { formItemLayout } from "../../services/constants";
import { UserRoleTypes } from "../../services/enums";

interface IProps {
  data: IUser;
  form: FormInstance;
}

const UserForm: FC<IProps> = ({ data, form }) => {
  const [loading, setLoading] = useState(false);
  const [changedPhotoFile, setChangedPhotoFile] = useState(null);
  const [changedPhotoFileBase64, setChangedPhotoFileBase64] =
    useState<any>(null);

  const { Dragger } = Upload;

  const navigate = useNavigate();

  const create = async (values: IUser) => {
    setLoading(true);

    try {
      const response = await UsersService.create(values);

      if (values.password) {
        try {
          await updatePassword(response.data.result.id, values.password);
          notification.success({ message: `User password has been created` });
        } catch (errors: any) {
          checkErrorHandler(errors);
        }
      }
      checkFinishHandler("created");
    } catch (errors: any) {
      checkErrorHandler(errors);
    }
  };

  const update = async (values: IUser) => {
    setLoading(true);

    try {
      await UsersService.update({ ...data, ...values });

      if (values.password) {
        try {
          await updatePassword(data?.id, values.password);
          notification.success({ message: `User password has been updated` });
        } catch (errors: any) {
          checkErrorHandler(errors);
        }
      }
      checkFinishHandler("updated");
    } catch (errors: any) {
      checkErrorHandler(errors);
    }
  };

  const updatePassword = async (userId: string, password: string) => {
    return UsersService.updatePassword(userId, password);
  };

  const checkFinishHandler = (message: string) => {
    notification.success({ message: `User has been ${message}` });

    setLoading(false);
    navigate(ControlPanelNav.USERS);
  };

  const checkErrorHandler = (errors: any) => {
    setLoading(false);

    CommonService.showAllErrors(errors);
  };

  const handleDraggerBeforeUpload = (file: File) => {
    if (file.size / 1024 / 1024 > 5) {
      notification.error({ message: "Image must be smaller than 5MB!" });
    }

    return false;
  };

  const handleChangePhotoFile = (info: any) => {
    setChangedPhotoFile(info.file);

    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setChangedPhotoFileBase64(reader.result)
    );
    reader.readAsDataURL(info.file);
  };

  const uploadButton = (
    <div className="fs-22">
      <p className="ant-upload-drag-icon">
        {loading ? <LoadingOutlined /> : <UploadOutlined />}
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
    </div>
  );

  const uploadPhoto = async (file: any) => {
    if (file) {
      try {
        const formData = CommonService.prepareFormData(file);

        await UsersService.uploadPhoto(data?.id, formData);
      } catch (errors) {
        checkErrorHandler(errors);
      }
    }
    return null;
  };

  const handleSubmitForm = async (values: any) => {
    if (!!data?.id) {
      await update(values);
    } else {
      await create(values);
    }
    uploadPhoto(changedPhotoFile);
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      requiredMark={true}
      onFinish={handleSubmitForm}
    >
      <Form.Item
        label="First Name"
        name="firstName"
        rules={[{ required: true, message: "Please input First Name!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[{ required: true, message: "Please input Last Name!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ type: "email", message: "Invalid email!" }]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Phone Number"
        name="phoneNumber"
        rules={[{ required: true, message: "Please input Last Name!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password(optional)"
        name="password"
        rules={[{ required: false, message: "Please input password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Role"
        name="role"
        rules={[{ required: true, message: "Please select a role!" }]}
      >
        <Select>
          {CommonService.getNameValues(UserRoleTypes).map((el) => {
            if (el.value !== UserRoleTypes.Admin) {
              return (
                <Select.Option value={el.value} key={el.value}>
                  {el.name}
                </Select.Option>
              );
            }
            return null;
          })}
        </Select>
      </Form.Item>

      <Form.Item label="Current photo" name="photo">
        {data && (
          <Image
            height="100px"
            src={`${APIRoutesBase.API_BASE}/${data?.photoPath}`}
          />
        )}
      </Form.Item>

      <Form.Item label="Choose new photo (optional)" name="photoPath">
        <Dragger
          accept="image/png,image/jpeg"
          listType="picture-card"
          showUploadList={false}
          beforeUpload={handleDraggerBeforeUpload}
          onChange={handleChangePhotoFile}
          multiple={false}
        >
          {changedPhotoFileBase64 ? (
            <div>
              <img
                src={changedPhotoFileBase64}
                alt="Logo url"
                style={{ maxHeight: 102, maxWidth: 102 }}
              />
            </div>
          ) : (
            uploadButton
          )}
        </Dragger>
        {changedPhotoFile && (
          <CloseCircleOutlined
            className="upload-close"
            onClick={() => {
              setChangedPhotoFile(null);
              setChangedPhotoFileBase64(null);
            }}
          />
        )}
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
