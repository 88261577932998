import { MenuOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Table, TablePaginationConfig } from "antd";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { ControlPanelNav } from "../../areas/controlPanel/config/navigation";
import useHttpGet from "../../hooks/useHttpGet";
import { APIRoutes } from "../../http/apiRoutes";
import { IUser } from "../../models/Users/user.model";
import { IResultListResponse } from "../../models/response/result-list.model";
import CommonService from "../../services/common.service";
import { RecordStates, UserRoleTypes } from "../../services/enums";
import GetColumnSearchProps from "../Layout/Common/Adapters/TableFilter";

const List: FC = () => {
  const [filter, setFilter] = useState({
    roles: null,
    firstName: null,
    lastName: null,
    email: null,
    states: null,
    sortPropertyName: null,
    sortAscending: false,
  });

  const [pagination, setPagination] = useState<any>({
    showSizeChanger: true,
    pageSizeOptions: ["30", "50", "100"],
    defaultPageSize: 30,
    pageSize: 30,
    current: 1,
    total: 1,
  });

  const { fetchedData, loading } = useHttpGet<IResultListResponse<IUser>>(
    `${APIRoutes.USERS}`,
    {
      dependencies: [filter],
      query: {
        ...filter,
        pageSize: pagination.pageSize,
        pageNumber: pagination.current - 1,
      },
      defaultValue: {} as IResultListResponse<IUser>,
      reject: CommonService.showAllErrors,
      resolve: (response) => {
        setPagination({
          ...pagination,
          total: response.result.totalElementsCount,
        });
      },
    }
  );

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) => {
    setPagination({ ...pagination, ...newPagination });

    const newFilter: any = { ...filter };

    newFilter.roles = filters.role ? filters.role : null;
    newFilter.firstName = filters.firstName ? filters.firstName[0] : null;
    newFilter.lastName = filters.lastName ? filters.lastName[0] : null;
    newFilter.email = filters.email ? filters.email[0] : null;
    newFilter.states = filters.state ? filters.state : null;
    newFilter.sortPropertyName = sorter.column ? sorter.field : null;
    newFilter.sortAscending = sorter.order === "ascend";

    setFilter(newFilter);
  };

  const menu = (record: IUser) => (
    <Menu>
      <Menu.Item key="1">
        <Link to={`${ControlPanelNav.USER_BASE_EDIT}/${record?.id}`}>Edit</Link>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Role",
      dataIndex: "role",
      filters: [
        {
          text: "Slave",
          value: UserRoleTypes.Slave,
        },
        {
          text: "Admin",
          value: UserRoleTypes.Admin,
        },
      ],
      onFilter: (value: any, record: IUser) => record.role === value,
      render: (role: UserRoleTypes) => UserRoleTypes[role],
    },
    {
      title: "First name",
      dataIndex: "firstName",
      sorter: true,
      ...GetColumnSearchProps("firstName"),
      render: (firstName: string, record: IUser) => (
        <Link
          target="_blank"
          to={`${ControlPanelNav.USER_BASE_EDIT}/${record?.id}`}
        >
          {firstName}
        </Link>
      ),
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      sorter: true,
      ...GetColumnSearchProps("lastName"),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
      ...GetColumnSearchProps("email"),
      render: (email: string) => <Link to={`mailto:${email}`}>{email}</Link>,
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: true,
      filters: [
        {
          text: RecordStates[RecordStates.Active],
          value: RecordStates.Active,
        },
        {
          text: RecordStates[RecordStates.Pending],
          value: RecordStates.Pending,
        },
        {
          text: RecordStates[RecordStates.Locked],
          value: RecordStates.Locked,
        },
        {
          text: RecordStates[RecordStates.Archived],
          value: RecordStates.Archived,
        },
        {
          text: RecordStates[RecordStates.Deleted],
          value: RecordStates.Deleted,
        },
      ],
      onFilter: (value: any, record: IUser) => record.state === value,
      render: (state: RecordStates) => RecordStates[state],
    },
    {
      title: "",
      dataIndex: "id",
      render: (_: any, record: IUser) => {
        return (
          <Dropdown overlay={menu(record)}>
            <Button>
              <MenuOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={fetchedData.result?.list || []}
        onChange={handleTableChange}
        pagination={pagination}
        rowKey="id"
        loading={loading}
        size="small"
      />
    </>
  );
};

export default List;
