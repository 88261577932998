import { FC, useState } from "react";
import {
  Table,
  Menu,
  Button,
  Dropdown,
  Popconfirm,
  TablePaginationConfig,
  Popover,
  Spin,
  List as AntList,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import { EyeOutlined, MenuOutlined } from "@ant-design/icons";
import GetColumnSearchProps from "../Layout/Common/Adapters/TableFilter";
import useHttpGet from "../../hooks/useHttpGet";
import { IResultListResponse } from "../../models/response/result-list.model";
import { APIRoutes } from "../../http/apiRoutes";
import CommonService from "../../services/common.service";
import { IMagicPage } from "../../models/MagicPages/magicPage.model";
import { IResultResponse } from "../../models/response/result.model";
import { IMagicPageHistory } from "../../models/magic-page-history.model";
import MagicPageHistoryFormModal from "./MagicPageHistoryFormModal";
import MagicPagesService from "../../services/MagicPages/magicPages.service";
import { random } from "lodash";
import { MagicPagesTypes, mapEnum } from "../../services/enums";
import { ControlPanelNav } from "../../areas/controlPanel/config/navigation";

const List: FC = () => {
  const [pageHistoryItem, setPageHistoryItem] = useState<IMagicPageHistory>(
    {} as IMagicPageHistory
  );
  const [isOpen, setIsOpen] = useState(false);

  const [filter, setFilter] = useState({
    sortPropertyName: null,
    sortAscending: false,
    updateTrigger: 0,
  });

  const [pagination, setPagination] = useState<any>({
    showSizeChanger: true,
    pageSizeOptions: ["30", "50", "100"],
    defaultPageSize: 30,
    pageSize: 30,
    current: 1,
    total: 1,
  });

  const { fetchedData, loading } = useHttpGet<IResultListResponse<IMagicPage>>(
    `${APIRoutes.MAGIC_PAGES}`,
    {
      dependencies: [filter, filter.updateTrigger],
      query: {
        ...filter,
        pageSize: pagination.pageSize,
        pageNumber: pagination.current - 1,
      },
      defaultValue: {} as IResultListResponse<IMagicPage>,
      reject: CommonService.showAllErrors,
    }
  );

  const { fetchedData: pageHistory, loading: pageHistoryLoading } = useHttpGet<
    IResultResponse<IMagicPageHistory[]>
  >(`${APIRoutes.MAGIC_PAGES_HISTORY_FOR_PAGE}/${pageHistoryItem?.id}`, {
    dependencies: [pageHistoryItem?.id],
    defaultValue: [],
    condition: !!pageHistoryItem?.id,
    reject: CommonService.showAllErrors,
  });

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) => {
    setPagination({ ...pagination, ...newPagination });

    const newFilter: any = { ...filter };

    newFilter.title = filters.title ? filters?.title[0] : null;
    newFilter.description = filters.description ? filters.description[0] : null;
    newFilter.sortPropertyName = sorter.column ? sorter.field : null;
    newFilter.sortAscending = sorter.order === "ascend";

    setFilter(newFilter);
  };

  const pageHistoryItemChangeHandler = (record: IMagicPageHistory) => {
    setIsOpen(true);
    setPageHistoryItem(record);
  };

  const history = (
    <Spin spinning={pageHistoryLoading}>
      <AntList
        size="small"
        bordered
        dataSource={pageHistory?.result || []}
        renderItem={(item: IMagicPageHistory) => (
          <AntList.Item>
            {item.title}
            <Typography.Text
              mark
              onClick={() => pageHistoryItemChangeHandler(item)}
            >
              <EyeOutlined />
            </Typography.Text>
          </AntList.Item>
        )}
      />
    </Spin>
  );

  const handleDelete = (id: string) => {
    MagicPagesService.delete(id);
    setFilter({ ...filter, updateTrigger: random() });
  };

  const menu = (record: IMagicPage) => (
    <Menu>
      <Menu.Item key="1">
        <Link to={`${ControlPanelNav.MAGIC_PAGES}/${record?.id}`}>Edit</Link>
      </Menu.Item>

      <Menu.Item key="3">
        <Popover
          placement="topRight"
          title="History"
          content={history}
          trigger="click"
        >
          <Button type="link">History</Button>
        </Popover>
      </Menu.Item>

      <Menu.Item key="3">
        <Link to={`${ControlPanelNav.MAGIC_PAGES_CONTENT_ITEM}/${record?.id}`}>
          Content items
        </Link>
      </Menu.Item>

      {record.type === MagicPagesTypes.Custom && (
        <Menu.Item key="2">
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => handleDelete(record.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            Delete
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: true,
      ...GetColumnSearchProps("title"),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: true,
      ...GetColumnSearchProps("description"),
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text: any) =>
        mapEnum(MagicPagesTypes).filter((a) => a.id === text.toString())[0]
          ?.name,
    },
    {
      title: "Updated",
      dataIndex: "updatedOnUtc",
      render: (text: any, record: IMagicPage) => (
        <>
          {record?.updatedOnUtc} <br /> by {record?.updatedByUser.name}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      render: (_: any, record: IMagicPage) => {
        return (
          <Dropdown overlay={menu(record)}>
            <Button>
              <MenuOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={fetchedData.result?.list || []}
        onChange={handleTableChange}
        pagination={pagination}
        rowKey="id"
        loading={loading}
        size="small"
      />

      <MagicPageHistoryFormModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={pageHistoryItem}
      />
    </>
  );
};

export default List;
