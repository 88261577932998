import React from "react";

export interface IRoute {
  path: string;
  element: React.ReactElement;
}

export enum Areas {
  CONTROL_PANEL = "controlpanel",
}

export enum UIRoutes {
  LOGIN = "login",
  ACCOUNT = "account",
  PROJECTS = "projects",
  ABOUT_US = "about-us",
  CONTACTS = "contacts",
  REGISTER = "register",
  FORGOT_PASSWORD = "forgot-password",
  RESTORE_PASSWORD = "restore-password/:code",

  PAGE_BASE_VIEW = "pages",
  PAGE_VIEW_ITEM = "pages/:name/:url/:id",
  PAGE_VIEW = "pages/:name/:type",

  FILE_BANK = "file-bank",
  FILE_BANK_BASE_VIEW = "file-bank/article",
  FILE_BANK_VIEW = "file-bank/article/:name/:id",

  IMAGE_BANK = "image-bank",
  IMAGE_BANK_BASE_VIEW = "image-bank/article",
  IMAGE_BANK_VIEW = "image-bank/article/:name/:id",
}

export const ControlPanelRoutes = {
  DASHBOARD: "dashboard",
  PROFILE: "profile",

  TASKS: "tasks",

  USERS: "users",
  USER_BASE_EDIT: "user/edit",
  USER_EDIT: "user/edit/:id",
  USER_CREATE: "user/create",

  MAGIC_PAGES: "magic-pages",
  MAGIC_PAGE_CREATE: "magic-pages/create",
  MAGIC_PAGE_EDIT: "magic-pages/:id",

  MAGIC_PAGES_CONTENT_ITEM_Base: "magic-pages-content-items",
  MAGIC_PAGES_CONTENT_ITEM: "magic-pages-content-items/:pageId",
  MAGIC_PAGE_CONTENT_ITEM_CREATE_Base: "magic-pages-content-items/create/",
  MAGIC_PAGE_CONTENT_ITEM_CREATE: "magic-pages-content-items/create/:pageId",
  MAGIC_PAGE_CONTENT_ITEM_EDIT: "magic-pages-content-items/edit/:id",

  SYSTEM_SETTINGS: "system-settings",

  EMAIL_TEMPLATES: "email-templates",
  EMAIL_TEMPLATES_BASE_EDIT: "email-templates/edit",
  EMAIL_TEMPLATES_EDIT: "email-templates/edit/:id",

  FILE_BANK: "file-bank",

  IMAGE_BANK: "image-bank",
};
