import React, { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UIRoutes } from '.';

interface IProtectedRouteProps {
  path?: string;
  element?: React.ReactElement;
}

const AuthGuard: FC<IProtectedRouteProps> = (props) => {
  const location = useLocation();

  const isAuth = !!JSON.parse(localStorage.getItem('app.Authorized') as string);

  const hasToken = !!localStorage.getItem(
    '48bceb9d-e1a4-4c77-9d62-9b0e9748c07e'
  );

  const isUserAuthorized = hasToken ? true : isAuth;

  if (!isUserAuthorized) {
    return (
      <Navigate to={`/${UIRoutes.LOGIN}`} replace state={{ from: location }} />
    );
  }

  return <Outlet />;
};
export default AuthGuard;
