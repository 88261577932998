import { Button, Form, Input, Modal, notification } from "antd";
import { FC, useState } from "react";

import { useForm } from "antd/lib/form/Form";
import CommonService from "../../services/common.service";
import { formItemLayout } from "../../services/constants";

const InquiryForm: FC = () => {
  const [form] = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const create = async (values: {}) => {
    try {
      //todo: create request

      checkFinishHandler("sent");
      setIsModalVisible(false);
    } catch (errors) {
      checkErrorHandler(errors);
    }
  };

  const checkFinishHandler = (message: string) => {
    notification.success({ message: `The inquiry has been ${message}` });
    form.setFieldsValue({ name: "", email: "", subject: "", inquiry: "" });
    setIsModalVisible(false);
  };

  const checkErrorHandler = (errors: any) => {
    CommonService.showAllErrors(errors);
  };

  const handleSubmitForm = async (values: any) => {
    await create(values);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={"Send us an inquiry"}
        visible={isModalVisible}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="send" type="primary" onClick={() => form.submit()}>
            Send
          </Button>,
        ]}
        onCancel={handleCancel}
      >
        <Form
          {...formItemLayout}
          form={form}
          requiredMark={true}
          onFinish={handleSubmitForm}
        >
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>

          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: true, message: "Please input E-mail!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Subject" name="subject">
            <Input />
          </Form.Item>

          <Form.Item
            label="Inquiry"
            name="inquiry"
            rules={[{ required: true, message: "Please input Inquiry!" }]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>

      <Button
        type="primary"
        size="large"
        onClick={() => setIsModalVisible(true)}
      >
        Contact us using form
      </Button>
    </>
  );
};

export default InquiryForm;
