import { Card } from 'antd';
import React, { FC } from 'react';

interface IProps {
  children: React.ReactNode;
  title: React.ReactNode;
  extra?: React.ReactNode;
}

const CPPageTemplate: FC<IProps> = ({ children, title, extra }) => {
  return (
    <div>
      <Card title={title} extra={extra}>
        {children}
      </Card>
    </div>
  );
};

export default CPPageTemplate;
