import { AxiosResponse } from 'axios';

import RequestsService from '../requests.service';
import { APIRoutes } from '../../http/apiRoutes';
import { IResultResponse } from '../../models/response/result.model';
import { INotification } from '../../models/Notifications/notification.model';

export default class NotificationsService {
  static async read(
    notificationIds: string[]
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.postMethod<IResultResponse<any>>(
      APIRoutes.NOTIFICATIONS_READ,
      notificationIds
    );
  }

  static async archive(
    notificationIds: string[]
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.postMethod<IResultResponse<any>>(
      APIRoutes.NOTIFICATIONS_ARCHIVE,
      notificationIds
    );
  }

  static async create(
    notification: INotification
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.postMethod<IResultResponse<any>>(
      APIRoutes.NOTIFICATIONS,
      notification
    );
  }
}
