import { AxiosResponse } from 'axios';
import RequestsService from '../requests.service';
import { APIRoutes } from '../../http/apiRoutes';
import { IResultResponse } from '../../models/response/result.model';
import { IMagicFile } from '../../models/MagicFiles/magicFile.model';

export default class MagicFilesService {
  static async create(
    magicFile: IMagicFile
  ): Promise<AxiosResponse<IResultResponse<IMagicFile>>> {
    return RequestsService.postMethod<IResultResponse<IMagicFile>>(
      APIRoutes.MAGIC_FILES,
      magicFile
    );
  }

  static async update(
    magicFile: IMagicFile
  ): Promise<AxiosResponse<IResultResponse<IMagicFile>>> {
    return RequestsService.putMethod<IResultResponse<IMagicFile>>(
      `${APIRoutes.MAGIC_FILES}`,
      magicFile
    );
  }

  static async upload(
    file: FormData
  ): Promise<AxiosResponse<IResultResponse<IMagicFile>>> {
    return RequestsService.postMethod<IResultResponse<IMagicFile>>(
      `${APIRoutes.MAGIC_FILES}`,
      file
    );
  }

  static async delete(
    magicFileId: string
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.deleteMethod<IResultResponse<any>>(
      `${APIRoutes.MAGIC_FILES}/${magicFileId}`
    );
  }
}
