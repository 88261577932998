import { IUser } from '../../../models/Users/user.model';
import { AuthAction, AuthActionEnum, AuthState } from './types';

const initialState: AuthState = {
  isAuth: false,
  currentUser: {} as IUser,
};

export default function authReducer(
  state = initialState,
  action: AuthAction
): AuthState {
  switch (action.type) {
    case AuthActionEnum.SET_AUTH:
      return { ...state, isAuth: action.payload };
    case AuthActionEnum.SET_CURRENT_USER:
      return { ...state, currentUser: action.payload };
    default:
      return state;
  }
}
