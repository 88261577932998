import { AxiosResponse } from 'axios';
import RequestsService from './requests.service';
import { APIRoutes } from '../http/apiRoutes';
import { IAuthResult } from '../models/auth.model';
import { IResultResponse } from '../models/response/result.model';
import { UIRoutes } from '../router';

interface IForgotPasswordChange {
  newPassword: string;
  resetPasswordToken: string;
}

function createAuthHash(username: string, password: string) {
  return Buffer.from(`${username}:${password}`).toString('base64');
}

export default class AuthService {
  static async login(
    login: string,
    password: string
  ): Promise<AxiosResponse<IResultResponse<IAuthResult>>> {
    return RequestsService.postMethod<IResultResponse<IAuthResult>>(
      APIRoutes.AUTH_LOGIN,
      null,
      {
        headers: {
          Authorization: `Basic ${createAuthHash(login, password)}`,
        },
      }
    );
  }

  static async forgotPasswordRequest(
    email: string
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.putMethod<IResultResponse<any>>(
      APIRoutes.AUTH_FORGOT_PASSWORD_REQUEST,
      { email }
    );
  }

  static async forgotPasswordChange(
    data: IForgotPasswordChange
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.putMethod<IResultResponse<any>>(
      APIRoutes.AUTH_FORGOT_PASSWORD_CHANGE,
      data
    );
  }

  static logout = (setIsAuth: any, navigate: any) => {
    setIsAuth(false);
    localStorage.removeItem('48bceb9d-e1a4-4c77-9d62-9b0e9748c07e');
    localStorage.removeItem('app.Authorized');

    navigate(`/${UIRoutes.ACCOUNT}/${UIRoutes.LOGIN}`);
  };
}
