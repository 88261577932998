import React, { FC } from 'react';
import { Row } from 'antd';
import { Helmet } from 'react-helmet';
import useHttpGet from '../../../hooks/useHttpGet';
import { APIRoutes } from '../../../http/apiRoutes';
import { IMagicPage } from '../../../models/MagicPages/magicPage.model';
import { IResultResponse } from '../../../models/response/result.model';
import { MagicPagesTypes } from '../../../services/enums';
import CommonService from '../../../services/common.service';
import AuthTemplate from '../../../components/Layout/AuthLayout/Template';
import LoginForm from '../../../components/Login';
import { REACT_APP_SITE_TITLE } from '../../../services/constants';

const Login: FC = () => {
  const { fetchedData, loading } = useHttpGet<IResultResponse<IMagicPage>>(
    `${APIRoutes.MAGIC_PAGES}/type/${MagicPagesTypes.Login}`,
    {
      defaultValue: {} as IResultResponse<IMagicPage>,
      reject: CommonService.showAllErrors,
    }
  );

  return (
    <Row justify='center'>
      <Helmet>
        <title>{`${fetchedData.result?.seoTitle} | ${REACT_APP_SITE_TITLE}`}</title>
        <meta name='description' content={fetchedData.result?.seoDescription} />
      </Helmet>

      <AuthTemplate data={fetchedData?.result} loading={loading}>
        <LoginForm />
      </AuthTemplate>
    </Row>
  );
};

export default Login;
