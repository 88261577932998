import { FC, useState } from "react";
import { Button, Form, Input, notification, Upload } from "antd";

import {
  CloseCircleOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import CommonService from "../../services/common.service";
import { formItemLayout } from "../../services/constants";
import { MagicFileTypes } from "../../services/enums";
import MagicFilesService from "../../services/MagicFiles/magicFiles.service";

const ImageBankForm: FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [changedImage, setChangedImage] = useState(null);
  const [changedImageBase64, setChangedImageBase64] = useState<any>(null);

  const { Dragger } = Upload;

  const create = async (imageName: string, image: any) => {
    if (image) {
      setLoading(true);
      try {
        const formData = CommonService.prepareFormData(image);
        formData.append("name", imageName);
        formData.append("type", MagicFileTypes.Image);

        const uploadResult = await MagicFilesService.upload(formData);

        if (uploadResult.status === 200) {
          checkFinishHandler("uploaded");
        } else {
          notification.error({ message: "Image isn't uploaded" });
        }
        setLoading(false);
      } catch (errors) {
        checkErrorHandler(errors);
      }
    }
  };

  const checkFinishHandler = (message: string) => {
    notification.success({ message: `The image has been ${message}` });

    setLoading(false);
  };

  const checkErrorHandler = (errors: any) => {
    setLoading(false);

    CommonService.showAllErrors(errors);
  };

  const handleSubmitForm = async (values: any) => {
    create(values.imageName, changedImage);
    form.setFieldsValue({ imageName: "" });
    setChangedImage(null);
    setChangedImageBase64(null);
  };

  const handleDraggerBeforeUpload = (image: File) => {
    if (image.size / 1024 / 1024 > 5) {
      notification.error({ message: "Image must be smaller than 5MB!" });
    }

    return false;
  };

  const handleChangeImage = (info: any) => {
    setChangedImage(info.file);

    const reader = new FileReader();
    reader.addEventListener("load", () => setChangedImageBase64(reader.result));
    reader.readAsDataURL(info.file);
  };

  const uploadButton = (
    <div className="fs-22">
      <p className="ant-upload-drag-icon">
        {loading ? <LoadingOutlined /> : <UploadOutlined />}
      </p>
      <p className="ant-upload-text">
        Click or drag image to this area to upload
      </p>
    </div>
  );

  return (
    <Form
      {...formItemLayout}
      form={form}
      requiredMark={true}
      onFinish={handleSubmitForm}
    >
      <Form.Item label="Name" name="imageName">
        <Input />
      </Form.Item>

      <Form.Item label="Image" name="image">
        <Dragger
          accept="image/png,image/jpeg"
          listType="picture-card"
          showUploadList={false}
          beforeUpload={handleDraggerBeforeUpload}
          onChange={handleChangeImage}
        >
          {changedImageBase64 ? (
            <div>
              <img
                src={changedImageBase64}
                alt="Logo url"
                style={{ maxHeight: 102, maxWidth: 102 }}
              />
            </div>
          ) : (
            uploadButton
          )}
        </Dragger>

        {changedImage && (
          <CloseCircleOutlined
            className="upload-close"
            onClick={() => {
              setChangedImage(null);
              setChangedImageBase64(null);
            }}
          />
        )}
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ImageBankForm;
