import { Button, Form, Input, notification } from "antd";
import { FC, useState } from "react";

import { useForm } from "antd/lib/form/Form";
import CommonService from "../../services/common.service";
import { labelsAboveFormItemLayout } from "../../services/constants";

const StandAloneForm: FC = () => {
  const [form] = useForm();

  const checkFinishHandler = (message: string) => {
    notification.success({ message: `The inquiry has been ${message}` });
    form.setFieldsValue({ name: "", email: "", subject: "", inquiry: "" });
  };

  const checkErrorHandler = (errors: any) => {
    CommonService.showAllErrors(errors);
  };

  const handleSubmitForm = async (values: any) => {
    try {
      //todo: create request

      checkFinishHandler("sent");
    } catch (errors) {
      checkErrorHandler(errors);
    }
  };

  return (
    <Form
      {...labelsAboveFormItemLayout}
      form={form}
      labelAlign="left"
      requiredMark={true}
      onFinish={handleSubmitForm}
    >
      <Form.Item label="Name" name="name">
        <Input size="large" />
      </Form.Item>

      <Form.Item
        label="E-mail"
        name="email"
        rules={[{ required: true, message: "Please input E-mail!" }]}
      >
        <Input size="large" />
      </Form.Item>

      <Form.Item
        label="Inquiry"
        name="inquiry"
        rules={[{ required: true, message: "Please input Inquiry!" }]}
      >
        <Input.TextArea size="large" />
      </Form.Item>

      <Button
        key="send"
        type="primary"
        shape="round"
        size="large"
        onClick={() => form.submit()}
      >
        Send
      </Button>
    </Form>
  );
};

export default StandAloneForm;
