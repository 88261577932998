import { AxiosResponse } from 'axios';

import { APIRoutes } from '../../http/apiRoutes';
import { ISystemSettings } from '../../models/SystemSettings/systemSetting.model';
import { IResultResponse } from '../../models/response/result.model';
import { SettingsTypes } from '../enums';
import RequestsService from '../requests.service';

export default class SystemSettingsService {
  static async update(
    systemSettings: ISystemSettings
  ): Promise<AxiosResponse<IResultResponse<ISystemSettings>>> {
    return RequestsService.putMethod<IResultResponse<ISystemSettings>>(
      `${APIRoutes.SYSTEM_SETTINGS}/${systemSettings.id}`,
      systemSettings
    );
  }

  static async upload(
    id: SettingsTypes,
    file: FormData
  ): Promise<AxiosResponse<IResultResponse<ISystemSettings>>> {
    return RequestsService.putMethod<IResultResponse<any>>(
      `${APIRoutes.SYSTEM_SETTINGS}/${id}/file`,
      file
    );
  }
}
