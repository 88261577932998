import React, { FC } from 'react';
import { Form, Spin } from 'antd';
import { useParams } from 'react-router-dom';

import useHttpGet from '../../../../hooks/useHttpGet';
import { APIRoutes } from '../../../../http/apiRoutes';
import { IResultResponse } from '../../../../models/response/result.model';
import CPPageTemplate from '../../../../components/Layout/Common/CPPageTemplate';
import EmailTemplateForm from '../../../../components/EmailTemplates/Form';
import { IEmailTemplate } from '../../../../models/email-template.model';

const EmailTemplateEdit: FC = () => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const { fetchedData, loading } = useHttpGet<IResultResponse<IEmailTemplate>>(
    `${APIRoutes.EMAIL_TEMPLATES}/${id}`,
    {
      defaultValue: { result: {} as IEmailTemplate },
      condition: !!id,
      resolve: (response: IResultResponse<IEmailTemplate>) =>
        form.setFieldsValue(response.result),
    }
  );

  return (
    <CPPageTemplate title={`Update '${fetchedData.result?.subject}'`}>
      <Spin spinning={loading}>
        <EmailTemplateForm data={fetchedData.result} form={form} />
      </Spin>
    </CPPageTemplate>
  );
};

export default EmailTemplateEdit;
