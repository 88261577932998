import { FC } from "react";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet";
import PublicAndLPPageTemplate from "../../../components/Layout/Common/PublicAndLPPageTemplate";
import useHttpGet from "../../../hooks/useHttpGet";
import { APIRoutes } from "../../../http/apiRoutes";
import { IMagicPage } from "../../../models/MagicPages/magicPage.model";
import { IResultResponse } from "../../../models/response/result.model";
import { REACT_APP_SITE_TITLE } from "../../../services/constants";
import { MagicPagesTypes } from "../../../services/enums";
import { PageContentItems } from "./PageContentItems";

const Pages: FC = () => {
  const type: MagicPagesTypes = parseInt(useParams().type as string, 10);

  const { fetchedData, loading } = useHttpGet<IResultResponse<IMagicPage>>(
    `${APIRoutes.MAGIC_PAGES_TYPE}/${type}`,
    {
      dependencies: [type],
      defaultValue: { result: {} as IMagicPage },
    }
  );

  return (
    <PublicAndLPPageTemplate
      loading={loading}
      title={fetchedData.result?.title}
      description={fetchedData.result?.description}
      content={fetchedData.result?.content}
    >
      <Helmet>
        <title>{`${fetchedData.result?.seoTitle} | ${REACT_APP_SITE_TITLE}`}</title>
        <meta name="description" content={fetchedData.result?.seoDescription} />
      </Helmet>

      {PageContentItems(type)}
    </PublicAndLPPageTemplate>
  );
};

export default Pages;
