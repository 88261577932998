import React, { FC } from "react";
import { Card, Col, Row } from "antd";
import { useLocation } from "react-router-dom";

interface IProps {
  loading?: boolean;
  title?: React.ReactNode;
  description?: string;
  content?: string | React.ReactNode;
  children?: React.ReactNode;
  extra?: React.ReactNode;
  className?: string;
}

const PublicAndLPPageTemplate: FC<IProps> = ({
  loading,
  title,
  description,
  content,
  children,
}) => {
  const location: string = useLocation().pathname.split("/")[1];

  const columnWidth = 20;

  return (
    <div
      className={`content-height ${location === "home" && "w-100"}
      `}
    >
      <Row justify="center" className={location === "home" ? "" : "pt-1 pb-1"}>
        <Col xs={columnWidth + 4} sm={columnWidth + 4} md={columnWidth}>
          <Card
            className={location === "home" ? "w-100" : ""}
            bodyStyle={{
              padding: location === "home" ? "0" : "",
            }}
            title={
              <h1 className="fs-36 mr-1">
                {title}
                <small>{description || ""}</small>
              </h1>
            }
            loading={loading}
          >
            {typeof content === "string" ? (
              <span dangerouslySetInnerHTML={{ __html: content || "" }} />
            ) : (
              content
            )}
          </Card>

          {children}
        </Col>
      </Row>
    </div>
  );
};

export default PublicAndLPPageTemplate;
