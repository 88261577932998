import { Form, Spin } from 'antd';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import CPPageTemplate from '../../../../components/Layout/Common/CPPageTemplate';
import MagicPageForm from '../../../../components/MagicPages/Form';
import useHttpGet from '../../../../hooks/useHttpGet';
import { APIRoutes } from '../../../../http/apiRoutes';
import { IMagicPage } from '../../../../models/MagicPages/magicPage.model';
import { IResultResponse } from '../../../../models/response/result.model';

const MagicPageEdit: FC = () => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const { fetchedData, loading } = useHttpGet<IResultResponse<IMagicPage>>(
    `${APIRoutes.MAGIC_PAGES}/${id}`,
    {
      dependencies: [id],
      defaultValue: { result: {} as any },
      condition: !!id,
      resolve: (response: IResultResponse<IMagicPage>) =>
        form.setFieldsValue(response.result),
    }
  );

  return (
    <CPPageTemplate
      title={
        fetchedData.result?.title
          ? `Update '${fetchedData.result?.title}'`
          : 'Create'
      }
    >
      <Helmet title={`Update '${fetchedData.result?.title}'`} />
      <Spin spinning={loading}>
        <MagicPageForm data={fetchedData.result} form={form} />
      </Spin>
    </CPPageTemplate>
  );
};

export default MagicPageEdit;
