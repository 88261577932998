import {
  CloseCircleOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Form, Image, Input, Upload, notification } from "antd";
import { FC, useState } from "react";

import useHttpGet from "../../hooks/useHttpGet";
import { APIRoutes } from "../../http/apiRoutes";
import {
  ISystemSettings,
  getValue,
} from "../../models/SystemSettings/systemSetting.model";
import { IResultResponse } from "../../models/response/result.model";
import MagicFilesService from "../../services/MagicFiles/magicFiles.service";
import SystemSettingsService from "../../services/SystemSettings/systemSettings.service";
import CommonService from "../../services/common.service";
import { formItemLayout } from "../../services/constants";
import {
  MagicFileTypes,
  SettingsTypes,
  SystemSettingsFileNames,
} from "../../services/enums";

const SystemSettingsHeaderForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [changedLogoFile, setChangedLogoFile] = useState(null);
  const [changedLogoFileBase64, setChangedLogoFileBase64] = useState<any>(null);
  const [changedPartnerLogoFile, setChangedPartnerLogoFile] = useState(null);
  const [changedPartnerLogoFileBase64, setChangedPartnerLogoFileBase64] =
    useState<any>(null);

  const { Dragger } = Upload;

  const [form] = Form.useForm();

  const { fetchedData } = useHttpGet<IResultResponse<ISystemSettings[]>>(
    `${APIRoutes.SYSTEM_SETTINGS}/public`,
    {
      defaultValue: { result: [] as ISystemSettings[] },
      dependencies: [updateTrigger],
      resolve: (response: IResultResponse<ISystemSettings[]>) =>
        form.setFieldsValue({
          logoUrl: getValue(response.result, SettingsTypes.LogoUrl),
          logoTitle: getValue(response.result, SettingsTypes.LogoTitle),
          partnerLogoUrl: getValue(
            response.result,
            SettingsTypes.PartnerLogoUrl
          ),
          partnerLogoTitle: getValue(
            response.result,
            SettingsTypes.PartnerLogoTitle
          ),
        }),
      reject: CommonService.showAllErrors,
    }
  );

  const createSystemSetting = (id: SettingsTypes, newValue: string) => {
    const original = fetchedData?.result?.filter(
      (rec: any) => rec.id === id
    )[0];
    original.value = newValue;
    return original;
  };

  const checkErrorHandler = (errors: any) => {
    setLoading(false);

    CommonService.showAllErrors(errors);
  };

  const uploadImageAndUpdateSetting = async (id: SettingsTypes, file: any) => {
    if (file) {
      try {
        const formData = CommonService.prepareFormData(file);
        formData.append(
          "name",
          SystemSettingsFileNames.filter((a) => a.id === id)[0].name
        );
        formData.append("type", MagicFileTypes.Image);
        const uploadResult = await MagicFilesService.upload(formData);
        if (uploadResult.data?.result) {
          const logoSetting = createSystemSetting(
            id,
            uploadResult.data.result.path
          );
          await SystemSettingsService.update(logoSetting);
        }
      } catch (errors) {
        checkErrorHandler(errors);
      }
    }
  };

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    uploadImageAndUpdateSetting(SettingsTypes.LogoUrl, changedLogoFile);
    uploadImageAndUpdateSetting(
      SettingsTypes.PartnerLogoUrl,
      changedPartnerLogoFile
    );

    const logoTitleSetting = createSystemSetting(
      SettingsTypes.LogoTitle,
      values.logoTitle
    );
    await SystemSettingsService.update(logoTitleSetting);

    const partnerLogoTitleSetting = createSystemSetting(
      SettingsTypes.PartnerLogoTitle,
      values.partnerLogoTitle
    );
    await SystemSettingsService.update(partnerLogoTitleSetting);

    setUpdateTrigger(Date.now());

    setLoading(false);
    setChangedLogoFile(null);
    setChangedLogoFileBase64(null);
    setChangedPartnerLogoFile(null);
    setChangedPartnerLogoFileBase64(null);
    notification.success({ message: `Settings is updated` });
  };

  const handleDraggerBeforeUpload = (file: File) => {
    if (file.size / 1024 / 1024 > 5) {
      notification.error({ message: "Image must be smaller than 5MB!" });
    }

    return false;
  };

  const handleChangeLogoFile = (info: any) => {
    setChangedLogoFile(info.file);

    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setChangedLogoFileBase64(reader.result)
    );
    reader.readAsDataURL(info.file);
  };

  const uploadButton = (
    <div className="fs-22">
      <p className="ant-upload-drag-icon">
        {loading ? <LoadingOutlined /> : <UploadOutlined />}
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
    </div>
  );

  return !fetchedData?.result ? null : (
    <Form
      {...formItemLayout}
      form={form}
      requiredMark={false}
      onFinish={handleSubmitForm}
    >
      <Form.Item label="Current logo">
        {fetchedData?.result?.length > 0 && (
          <Image
            height="100px"
            src={`${APIRoutes.API_BASE}/${getValue(
              fetchedData.result,
              SettingsTypes.LogoUrl
            )}`}
          />
        )}
      </Form.Item>

      <Form.Item label="Choose new logo (optional)">
        <Dragger
          accept="image/png,image/jpeg"
          listType="picture-card"
          showUploadList={false}
          beforeUpload={handleDraggerBeforeUpload}
          onChange={handleChangeLogoFile}
        >
          {changedLogoFileBase64 ? (
            <div>
              <img
                src={changedLogoFileBase64}
                alt="Logo url"
                style={{ maxHeight: 102, maxWidth: 102 }}
              />
            </div>
          ) : (
            uploadButton
          )}
        </Dragger>
        {changedLogoFile && (
          <CloseCircleOutlined
            className="upload-close"
            onClick={() => {
              setChangedLogoFile(null);
              setChangedLogoFileBase64(null);
            }}
          />
        )}
      </Form.Item>

      <Form.Item label="Enter logo title (optional)" name="logoTitle">
        <Input placeholder="PAS logo title" />
      </Form.Item>

      <Form.Item label="Current partner logo">
        {fetchedData?.result?.length > 0 && (
          <Image
            height="100px"
            src={`${APIRoutes.API_BASE}/${getValue(
              fetchedData.result,
              SettingsTypes.PartnerLogoUrl
            )}`}
          />
        )}
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SystemSettingsHeaderForm;
