import { FC, useState } from "react";
import { Button, Modal } from "antd";
import CPPageTemplate from "../../../../components/Layout/Common/CPPageTemplate";
import ImageBankForm from "../../../../components/ImageBank/Form";
import List from "../../../../components/ImageBank/List";

const ImageBankList: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const createHandler = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setUpdateTrigger(Date.now());
    setIsOpen(false);
  };

  return (
    <CPPageTemplate
      title="Image Bank"
      extra={<Button onClick={createHandler}>CREATE</Button>}
    >
      <List updateTrigger={updateTrigger} />

      <Modal
        title={"Upload new image"}
        open={isOpen}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        onCancel={handleCancel}
        width={1000}
      >
        <ImageBankForm />
      </Modal>
    </CPPageTemplate>
  );
};

export default ImageBankList;
