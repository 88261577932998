import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Areas, ControlPanelRoutes, IRoute, UIRoutes } from "../router";
import AuthGuard from "./AuthGuard";

import AppLayout from "../components/Layout/AppLayout";
import AuthLayout from "../components/Layout/AuthLayout";
import ControlPanelLayout from "../components/Layout/ControlPanelLayout";

import Index from "../areas/public/pages/Index";
import ForgotPassword from "../areas/public/pages/ForgotPassword";
import Login from "../areas/public/pages/Login";
import Pages from "../areas/public/pages/Pages";
import PageContentItem from "../areas/public/pages/PageContentItem";
import RestorePassword from "../areas/public/pages/RestorePassword";

import ImageBankList from "../areas/controlPanel/pages//imageBank/List";

import Dashboard from "../areas/controlPanel/pages/dashboard/Dashboard";

import EmailTemplateEdit from "../areas/controlPanel/pages/emailTemplates/Edit";
import EmailTemplatesList from "../areas/controlPanel/pages/emailTemplates/List";

import FileBankList from "../areas/controlPanel/pages/fileBank/List";

import MagicPageEdit from "../areas/controlPanel/pages/magicPages/Edit";
import MagicPagesList from "../areas/controlPanel/pages/magicPages/List";

import MagicPagesContentItemsEdit from "../areas/controlPanel/pages/magicPagesContentItems/Edit";
import MagicPagesContentItemsList from "../areas/controlPanel/pages/magicPagesContentItems/List";

import SystemSettings from "../areas/controlPanel/pages/systemSettings";

import UserEdit from "../areas/controlPanel/pages/users/Edit";
import UsersList from "../areas/controlPanel/pages/users/List";

export const authRoutes: IRoute[] = [
  {
    path: UIRoutes.LOGIN,
    element: <Login />,
  },
  {
    path: UIRoutes.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: UIRoutes.RESTORE_PASSWORD,
    element: <RestorePassword />,
  },
];

export const appRoutes: IRoute[] = [
  {
    path: UIRoutes.PAGE_VIEW,
    element: <Pages />,
  },
  {
    path: UIRoutes.PAGE_VIEW_ITEM,
    element: <PageContentItem />,
  },
];

export const controlPanelRoutes: IRoute[] = [
  {
    path: ControlPanelRoutes.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: ControlPanelRoutes.USERS,
    element: <UsersList />,
  },
  {
    path: ControlPanelRoutes.USER_EDIT,
    element: <UserEdit />,
  },
  {
    path: ControlPanelRoutes.USER_CREATE,
    element: <UserEdit />,
  },

  {
    path: ControlPanelRoutes.FILE_BANK,
    element: <FileBankList />,
  },

  {
    path: ControlPanelRoutes.IMAGE_BANK,
    element: <ImageBankList />,
  },

  {
    path: ControlPanelRoutes.MAGIC_PAGES,
    element: <MagicPagesList />,
  },
  {
    path: ControlPanelRoutes.MAGIC_PAGE_CREATE,
    element: <MagicPageEdit />,
  },
  {
    path: ControlPanelRoutes.MAGIC_PAGE_EDIT,
    element: <MagicPageEdit />,
  },

  {
    path: ControlPanelRoutes.MAGIC_PAGES_CONTENT_ITEM,
    element: <MagicPagesContentItemsList />,
  },
  {
    path: ControlPanelRoutes.MAGIC_PAGE_CONTENT_ITEM_CREATE,
    element: <MagicPagesContentItemsEdit />,
  },
  {
    path: ControlPanelRoutes.MAGIC_PAGE_CONTENT_ITEM_EDIT,
    element: <MagicPagesContentItemsEdit />,
  },

  {
    path: ControlPanelRoutes.SYSTEM_SETTINGS,
    element: <SystemSettings />,
  },

  {
    path: ControlPanelRoutes.EMAIL_TEMPLATES,
    element: <EmailTemplatesList />,
  },
  {
    path: ControlPanelRoutes.EMAIL_TEMPLATES_EDIT,
    element: <EmailTemplateEdit />,
  },
];

const AppRouter: FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<AppLayout />}>
        {appRoutes.map((route, index) => (
          <Route key={`${route.path}${index}`} {...route} />
        ))}
        <Route path="" element={<Index />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>

      <Route path={`/${UIRoutes.ACCOUNT}/*`} element={<AuthLayout />}>
        {authRoutes.map((route, index) => (
          <Route key={`${route.path}${index}`} {...route} />
        ))}
        <Route path="*" element={<Navigate to={`${UIRoutes.LOGIN}`} />} />
      </Route>

      <Route element={<AuthGuard />}>
        <Route
          path={`/${Areas.CONTROL_PANEL}/*`}
          element={<ControlPanelLayout />}
        >
          {controlPanelRoutes.map((route, index) => (
            <Route {...route} key={`${route.path}-${index}`} />
          ))}
          <Route
            path="*"
            element={<Navigate to={ControlPanelRoutes.DASHBOARD} />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRouter;
