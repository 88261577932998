import React, { FC } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import AppHeader from './Header';
import AppPage from '../../AppPage';
import Sider from './Sider';
import useHttpGet from '../../../hooks/useHttpGet';
import { APIRoutes } from '../../../http/apiRoutes';
import { IResultResponse } from '../../../models/response/result.model';
import { ISystemSettings } from '../../../models/SystemSettings/systemSetting.model';
import CommonService from '../../../services/common.service';
import { SettingsTypes } from '../../../services/enums';

export const ControlPanelLayout: FC = () => {
  const { fetchedData: settings } = useHttpGet<
    IResultResponse<ISystemSettings[]>
  >(`${APIRoutes.SYSTEM_SETTINGS}/public`, {
    query: [
      SettingsTypes.LogoUrl,
      SettingsTypes.LogoTitle,
      SettingsTypes.PartnerLogoTitle,
      SettingsTypes.PartnerLogoUrl,
    ],
    defaultValue: { result: [] as ISystemSettings[] },
    reject: CommonService.showAllErrors,
  });

  return (
    <AppPage>
      <Layout>
        <Sider settings={settings.result} />

        <Layout>
          <AppHeader />
          <Layout.Content>
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>
    </AppPage>
  );
};

export default ControlPanelLayout;
