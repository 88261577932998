import { FC } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "antd";
import useHttpGet from "../../hooks/useHttpGet";
import { APIRoutes } from "../../http/apiRoutes";
import { IMagicPage } from "../../models/MagicPages/magicPage.model";
import { IResultResponse } from "../../models/response/result.model";
import { MagicPagesTypes } from "../../services/enums";

const ProjectsContentItems: FC = () => {
  const { fetchedData } = useHttpGet<IResultResponse<IMagicPage>>(
    `${APIRoutes.MAGIC_PAGES_TYPE}/${MagicPagesTypes.Projects}`,
    {
      defaultValue: { result: {} as IMagicPage },
    }
  );

  return (
    <>
      {fetchedData?.result?.magicContentItems?.map((f, index) => (
        <Card className="mt-2">
          {index % 2 === 0 ? (
            <Row>
              <Col className="mt-1" md={10}>
                <img
                  src={`${APIRoutes.API_BASE}/${f.image}`}
                  alt={f.title}
                  title={f.title}
                  className="w-100"
                />
              </Col>
              <Col md={14} className="p-1">
                <h2>
                  <Link to={`/pages/projects/${f.link}/${f?.id}`}>
                    {f.title}
                  </Link>
                </h2>
                <p>{f.description}</p>

                <table cellPadding={5}>
                  <tr>
                    <td>
                      <strong>Year/Duration:</strong>
                    </td>
                    <td>{f.customCss}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Industry:</strong>
                    </td>
                    <td>{f.background}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Technologies:</strong>
                    </td>
                    <td>{f.icon}</td>
                  </tr>
                </table>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md={14} className="p-1">
                <h2>
                  <Link to={`/pages/projects/${f.link}/${f?.id}`}>
                    {f.title}
                  </Link>
                </h2>
                <p>{f.description}</p>

                <table cellPadding={5}>
                  <tr>
                    <td>
                      <strong>Year/Duration:</strong>
                    </td>
                    <td>{f.customCss}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Industry:</strong>
                    </td>
                    <td>{f.background}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Technologies:</strong>
                    </td>
                    <td>{f.icon}</td>
                  </tr>
                </table>
              </Col>
              <Col className="mt-1" md={10}>
                <img
                  src={`${APIRoutes.API_BASE}/${f.image}`}
                  alt={f.title}
                  title={f.title}
                  className="w-100"
                />
              </Col>
            </Row>
          )}
        </Card>
      ))}
    </>
  );
};

export default ProjectsContentItems;
