import { FC, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import GetColumnSearchProps from "../Layout/Common/Adapters/TableFilter";
import useHttpGet from "../../hooks/useHttpGet";
import { APIRoutes } from "../../http/apiRoutes";
import CommonService from "../../services/common.service";
import { IMagicPageItem } from "../../models/MagicPages/magicPage.model";
import MagicPagesService from "../../services/MagicPages/magicPages.service";
import { random } from "lodash";
import { ControlPanelNav } from "../../areas/controlPanel/config/navigation";
import { IResultResponse } from "../../models/response/result.model";

interface IProps {
  magicPageId: string | undefined;
}
const List: FC<IProps> = ({ magicPageId }) => {
  const [filter, setFilter] = useState({
    sortPropertyName: null,
    sortAscending: false,
    updateTrigger: 0,
  });

  const { fetchedData, loading } = useHttpGet<
    IResultResponse<IMagicPageItem[]>
  >(`${APIRoutes.MAGIC_PAGES_Content_Items_ForPage}/${magicPageId}`, {
    dependencies: [filter, filter.updateTrigger],
    query: {
      ...filter,
    },
    defaultValue: {} as IResultResponse<IMagicPageItem[]>,
    reject: CommonService.showAllErrors,
  });

  const handleTableChange = (filters: any, sorter: any) => {
    const newFilter: any = { ...filter };

    newFilter.title = filters.title ? filters?.title[0] : null;
    newFilter.description = filters.description ? filters.description[0] : null;
    newFilter.sortPropertyName = sorter.column ? sorter.field : null;
    newFilter.sortAscending = sorter.order === "ascend";

    setFilter(newFilter);
  };

  const handleDelete = (id: string) => {
    MagicPagesService.delete(id);
    setFilter({ ...filter, updateTrigger: random() });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: true,
      ...GetColumnSearchProps("title"),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: true,
      ...GetColumnSearchProps("description"),
    },
    {
      title: "Updated",
      dataIndex: "updatedOnUtc",
      render: (text: any, record: IMagicPageItem) => (
        <>
          {/* {record?.updatedOnUtc} <br /> by {record?.updatedByUser.name} */}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      render: (_: any, record: IMagicPageItem) => {
        return (
          <>
            <Link
              to={`${ControlPanelNav.MAGIC_PAGES_CONTENT_ITEM}/edit/${record?.id}`}
            >
              Edit
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={fetchedData.result || []}
      onChange={handleTableChange}
      pagination={false}
      rowKey="id"
      loading={loading}
      size="small"
    />
  );
};

export default List;
