import React, { FC } from 'react';
import { Spin } from 'antd';
import { useActions } from '../hooks/useActions';
import useHttpGet from '../hooks/useHttpGet';
import { APIRoutes } from '../http/apiRoutes';
import { IResultResponse } from '../models/response/result.model';
import { IUser } from '../models/Users/user.model';

export interface IChildrenProps {
  children?: React.ReactNode | React.ReactChild;
}

const AppPage: FC<IChildrenProps> = ({ children }) => {
  const { setIsAuth, setCurrentUser } = useActions();

  const { loading } = useHttpGet<IResultResponse<IUser>>(
    `${APIRoutes.USERS}/my`,
    {
      resolve: (response: IResultResponse<IUser>) => {
        setIsAuth(true);
        setCurrentUser(response.result);
      },
      condition: !!JSON.parse(localStorage.getItem('app.Authorized') as string),
    }
  );

  if (loading) {
    return <Spin spinning />;
  }
  return <>{children}</>;
};

export default AppPage;
