import { Form, Spin } from "antd";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import CPPageTemplate from "../../../../components/Layout/Common/CPPageTemplate";
import useHttpGet from "../../../../hooks/useHttpGet";
import { APIRoutes } from "../../../../http/apiRoutes";
import { IMagicPageItem } from "../../../../models/MagicPages/magicPage.model";
import { IResultResponse } from "../../../../models/response/result.model";
import MagicPageContentItemForm from "../../../../components/ProjectContentItems/Form";

const MagicPageContentItemEdit: FC = () => {
  const { id, pageId } = useParams();
  const [form] = Form.useForm();

  const { fetchedData, loading } = useHttpGet<IResultResponse<IMagicPageItem>>(
    `${APIRoutes.MAGIC_PAGES_Content_Items}/${id}`,
    {
      dependencies: [id],
      defaultValue: { result: {} as any },
      condition: !!id,
      resolve: (response: IResultResponse<IMagicPageItem>) =>
        form.setFieldsValue(response.result),
    }
  );

  return (
    <CPPageTemplate
      title={
        pageId
          ? `Create for page '${fetchedData.result?.title}'`
          : `Edit '${fetchedData.result?.title}'`
      }
    >
      <Helmet title={`Update '${fetchedData.result?.title}'`} />

      <Spin spinning={loading}>
        <MagicPageContentItemForm data={fetchedData.result} form={form} />
      </Spin>
    </CPPageTemplate>
  );
};

export default MagicPageContentItemEdit;
