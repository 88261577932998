import React, { FC, useState } from 'react';
import { Form, Input, Button, notification, Alert } from 'antd';
import { Link, useParams } from 'react-router-dom';

import AuthService from '../../services/auth.service';
import { UIRoutes } from '../../router';

const RestorePasswordForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { code } = useParams();

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    try {
      await AuthService.forgotPasswordChange({
        newPassword: values.confirm,
        resetPasswordToken: code as string,
      });

      notification.success({
        message: 'Password has been changed. Now you can log in',
      });

      setLoading(false);
      setError('');
    } catch (errors: any) {
      setLoading(false);
      setError(errors?.response?.data?.errorMessages[0] || '');
    }
  };

  const validatePasword = ({ getFieldValue }: any) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue('newPassword') === value) {
        return Promise.resolve();
      }

      return Promise.reject(new Error('Passwords do not match'));
    },
  });

  const errorMessage = !!error ? (
    <Alert message={error} type='error' className='mb-1' />
  ) : null;

  return (
    <>
      {errorMessage}
      <Form
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSubmitForm}
      >
        <Form.Item
          label='New password'
          name='newPassword'
          rules={[{ required: true, message: 'Please input password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label='Confirm password'
          name='confirmPassword'
          rules={[
            { required: true, message: 'Please confirm password!' },
            validatePasword,
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <div className='mb-2'>
            <Link
              to={`/${UIRoutes.ACCOUNT}`}
              className='utils__link--blue utils__link--underlined'
            >
              Already have an account? Please log in
            </Link>
          </div>
          <Button type='primary' htmlType='submit' loading={loading}>
            Send
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default RestorePasswordForm;
