import { Areas, ControlPanelRoutes } from "../../../router";

export const ControlPanelNav = {
  DASHBOARD: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.DASHBOARD}`,

  TASKS: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.TASKS}`,

  USERS: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.USERS}`,
  USER_BASE_EDIT: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.USER_BASE_EDIT}`,
  USER_CREATE: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.USER_CREATE}`,

  MAGIC_PAGES: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.MAGIC_PAGES}`,
  MAGIC_PAGE_CREATE: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.MAGIC_PAGE_CREATE}`,

  MAGIC_PAGES_CONTENT_ITEM: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.MAGIC_PAGES_CONTENT_ITEM_Base}`,
  MAGIC_PAGES_CONTENT_ITEM_CREATE: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.MAGIC_PAGE_CONTENT_ITEM_CREATE_Base}`,

  SYSTEM_SETTINGS: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.SYSTEM_SETTINGS}`,

  EMAIL_TEMPLATES: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.EMAIL_TEMPLATES}`,
  EMAIL_TEMPLATES_BASE_EDIT: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.EMAIL_TEMPLATES_BASE_EDIT}`,

  FILE_BANK: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.FILE_BANK}`,

  IMAGE_BANK: `/${Areas.CONTROL_PANEL}/${ControlPanelRoutes.IMAGE_BANK}`,
};
