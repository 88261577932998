import { FC, useState } from "react";
import { Button, Form, Input, notification, Upload } from "antd";
import CommonService from "../../services/common.service";
import { formItemLayout } from "../../services/constants";
import { MagicFileTypes } from "../../services/enums";
import MagicFilesService from "../../services/MagicFiles/magicFiles.service";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";

const FileBankForm: FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [changedFile, setChangedFile] = useState(null);

  const { Dragger } = Upload;

  const create = async (fileName: string, file: any) => {
    if (file) {
      setLoading(true);
      try {
        const formData = CommonService.prepareFormData(file);
        formData.append("name", fileName);
        formData.append("type", MagicFileTypes.File);

        const uploadResult = await MagicFilesService.upload(formData);

        if (uploadResult.status === 200) {
          checkFinishHandler("uploaded");
        } else {
          notification.error({ message: "File isn't uploaded" });
        }
        setLoading(false);
      } catch (errors) {
        checkErrorHandler(errors);
      }
    }
  };

  const checkFinishHandler = (message: string) => {
    notification.success({ message: `The file has been ${message}` });

    setLoading(false);
  };

  const checkErrorHandler = (errors: any) => {
    setLoading(false);

    CommonService.showAllErrors(errors);
  };

  const handleSubmitForm = async (values: any) => {
    create(values.fileName, changedFile);
    form.setFieldsValue({ fileName: "" });
    setChangedFile(null);
  };

  const handleDraggerBeforeUpload = (_: File) => {
    return false;
  };

  const handleChangeFile = (info: any) => {
    setChangedFile(info.file);
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      requiredMark={true}
      onFinish={handleSubmitForm}
    >
      <Form.Item label="Name" name="fileName">
        <Input />
      </Form.Item>
      <Form.Item label="File" name="file">
        <Dragger
          beforeUpload={handleDraggerBeforeUpload}
          onChange={handleChangeFile}
          multiple={false}
          maxCount={1}
          fileList={changedFile ? [changedFile] : []}
        >
          <p className="ant-upload-drag-icon">
            {loading ? <LoadingOutlined /> : <InboxOutlined />}
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FileBankForm;
