import { FC, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  notification,
  Popconfirm,
  Typography,
  Table,
  TablePaginationConfig,
} from "antd";
import { MenuOutlined } from "@ant-design/icons";

import GetColumnSearchProps from "../Layout/Common/Adapters/TableFilter";
import useHttpGet from "../../hooks/useHttpGet";
import { IResultListResponse } from "../../models/response/result-list.model";
import { APIRoutes } from "../../http/apiRoutes";
import CommonService from "../../services/common.service";
import MagicFilesService from "../../services/MagicFiles/magicFiles.service";
import { MagicFileTypes } from "../../services/enums";
import { IMagicFile } from "../../models/MagicFiles/magicFile.model";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: IMagicFile;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface IProps {
  updateTrigger: number;
}

const List: FC<IProps> = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([] as IMagicFile[]);
  const [editingKey, setEditingKey] = useState("");

  const [filter, setFilter] = useState({
    types: [MagicFileTypes.File],
    name: null,
    sortPropertyName: null,
    sortAscending: false,
    updateTrigger: 0,
  });

  const cancel = () => {
    setEditingKey("");
  };

  const [pagination, setPagination] = useState<any>({
    showSizeChanger: true,
    pageSizeOptions: ["30", "50", "100"],
    defaultPageSize: 30,
    pageSize: 30,
    current: 1,
    total: 1,
    onChange: cancel,
  });

  const { loading } = useHttpGet<IResultListResponse<IMagicFile>>(
    `${APIRoutes.MAGIC_FILES}`,
    {
      dependencies: [filter, filter.updateTrigger, props.updateTrigger],
      query: {
        ...filter,
        pageSize: pagination.pageSize,
        pageNumber: pagination.current - 1,
      },
      defaultValue: {} as IResultListResponse<IMagicFile>,
      resolve: (response) => {
        setPagination({
          ...pagination,
          total: response.result.totalElementsCount,
        });
        setData(response.result?.list);
      },
      reject: CommonService.showAllErrors,
    }
  );

  const isEditing = (record: IMagicFile) => record.id === editingKey;

  const edit = (record: Partial<IMagicFile> & { id: React.Key }) => {
    form.setFieldsValue({ name: "", path: "", loaded: "", ...record });
    setEditingKey(record.id);
  };

  const save = async (id: React.Key) => {
    try {
      const row = (await form.validateFields()) as IMagicFile;
      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = {
          ...newData[index],
          ...row,
        };
        newData.splice(index, 1, item);
        setData(newData);
        setEditingKey("");

        const updateResult = await MagicFilesService.update(item);
        if (updateResult.status === 200) {
          notification.success({ message: `The file name has been changed` });
        } else {
          notification.error({ message: `The file name hasn't been changed` });
        }
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
    setFilter({ ...filter, updateTrigger: new Date().getMilliseconds() });
  };

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) => {
    setPagination({ ...pagination, ...newPagination });

    const newFilter: any = { ...filter };

    newFilter.name = filters.name ? filters.name[0] : null;
    newFilter.sortPropertyName = sorter.column ? sorter.field : null;
    newFilter.sortAscending = sorter.order === "ascend";

    setFilter(newFilter);
  };

  const menu = (record: IMagicFile) => (
    <Menu>
      <Menu.Item key="1">
        <Typography.Link
          disabled={editingKey !== ""}
          onClick={() => edit(record)}
        >
          Edit
        </Typography.Link>
      </Menu.Item>

      <Menu.Item key="2">
        <Popconfirm
          title="Are you sure?"
          onConfirm={async () => {
            const deleteResult = await MagicFilesService.delete(record.id);
            if (deleteResult.status === 200) {
              setData(data.filter((i) => i.id !== record.id));
              notification.success({ message: `The file has been deleted` });
            } else {
              notification.error({ message: `The file hasn't been deleted` });
            }
          }}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          Delete
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      editable: true,
      ...GetColumnSearchProps("name"),
    },
    {
      title: "Path",
      dataIndex: "path",
      sorter: true,
      editable: false,
    },
    {
      title: "Loaded",
      dataIndex: "createdOnUtc",
      editable: false,
      sorter: true,
    },
    {
      title: "",
      dataIndex: "id",
      editable: false,
      render: (_: any, record: IMagicFile) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Typography.Link onClick={cancel}>Cancel</Typography.Link>
          </span>
        ) : (
          <Dropdown overlay={menu(record)}>
            <Button>
              <MenuOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IMagicFile) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        onChange={handleTableChange}
        pagination={pagination}
        rowKey="id"
        loading={loading}
        size="small"
      />
    </Form>
  );
};

export default List;
