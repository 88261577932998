import { AxiosResponse } from 'axios';

import RequestsService from '../requests.service';
import { APIRoutes } from '../../http/apiRoutes';
import { IResultResponse } from '../../models/response/result.model';
import { IEmailTemplate } from '../../models/email-template.model';

export default class EmailTemplatesService {
  static async update(
    template: IEmailTemplate
  ): Promise<AxiosResponse<IResultResponse<IEmailTemplate>>> {
    return RequestsService.putMethod<IResultResponse<IEmailTemplate>>(
      `${APIRoutes.EMAIL_TEMPLATES}/${template.id}`,
      template
    );
  }
}
