import { FC } from "react";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import CPPageTemplate from "../../../../components/Layout/Common/CPPageTemplate";
import { ControlPanelNav } from "../../config/navigation";
import List from "../../../../components/ProjectContentItems/List";

const MagicPagesContentItemsList: FC = () => {
  const navigate = useNavigate();
  const { pageId } = useParams();

  const createHandler = () => {
    navigate(`${ControlPanelNav.MAGIC_PAGES_CONTENT_ITEM_CREATE}/${pageId}`);
  };

  return (
    <CPPageTemplate
      title={"Content pages"}
      extra={<Button onClick={createHandler}>CREATE</Button>}
    >
      <List magicPageId={pageId} />
    </CPPageTemplate>
  );
};

export default MagicPagesContentItemsList;
