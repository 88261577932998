import React, { FC } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import AppHeader from "./components/Header/Header";
import AppFooter from "./components/Footer/Footer";
import { ISystemSettings } from "../../../models/SystemSettings/systemSetting.model";
import CommonService from "../../../services/common.service";
import useHttpGet from "../../../hooks/useHttpGet";
import { IResultResponse } from "../../../models/response/result.model";
import { APIRoutes } from "../../../http/apiRoutes";
import { SettingsTypes } from "../../../services/enums";
import AppPage from "../../AppPage";

export const AppLayout: FC = () => {
  const { fetchedData: settings } = useHttpGet<
    IResultResponse<ISystemSettings[]>
  >(`${APIRoutes.SYSTEM_SETTINGS}/public`, {
    query: [
      SettingsTypes.LogoUrl,
      SettingsTypes.LogoTitle,
      SettingsTypes.PartnerLogoTitle,
      SettingsTypes.PartnerLogoUrl,
      SettingsTypes.Copyright,
    ],
    defaultValue: { result: [] as ISystemSettings[] },
    reject: CommonService.showAllErrors,
  });

  return (
    <AppPage>
      <Layout className="app-layout">
        <AppHeader settings={settings.result || []} />

        <Layout.Content>
          <Outlet />

          <AppFooter settings={settings.result || []} />
        </Layout.Content>
      </Layout>
    </AppPage>
  );
};

export default AppLayout;
