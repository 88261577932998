import { FC } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "antd";
import useHttpGet from "../../hooks/useHttpGet";
import { APIRoutes } from "../../http/apiRoutes";
import { IMagicPage } from "../../models/MagicPages/magicPage.model";
import { IResultResponse } from "../../models/response/result.model";
import { MagicPagesTypes } from "../../services/enums";

const FaqContentItems: FC = () => {
  const { fetchedData } = useHttpGet<IResultResponse<IMagicPage>>(
    `${APIRoutes.MAGIC_PAGES_TYPE}/${MagicPagesTypes.Faq}`,
    {
      defaultValue: { result: {} as IMagicPage },
    }
  );

  return (
    <>
      <Row className="faq-blocks">
        {fetchedData?.result?.magicContentItems?.map((f, index) => (
          <Col className="mt-1 ml-1" md={11}>
            <Card className="mt-2">
              <div style={{ maxHeight: 200, overflowY: "hidden" }}>
                <img
                  src={`${APIRoutes.API_BASE}/${f.image}`}
                  alt={f.title}
                  title={f.title}
                  className="w-100"
                />
              </div>
              <h2>
                <Link to={`/pages/faq/${f.link}/${f?.id}`}>{f.title}</Link>
              </h2>
              <p>{f.description}</p>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default FaqContentItems;
