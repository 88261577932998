import { Col, Layout, Menu, Row } from "antd";
import { FC } from "react";
import { ISystemSettings } from "../../../../../models/SystemSettings/systemSetting.model";
import UpHeader from "../UpHeader";
import getMenuItems from './config/menuItemsData';

interface IProps {
  settings: ISystemSettings[];
}

const AppHeader: FC<IProps> = ({ settings }) => {
  return (
    <div className="header-height bottom-header-height">
      <UpHeader settings={settings} />

      <div className="dark-theme">
        <Layout.Header>
          <Row>
            <Col span={24}>
            <Menu
                key='header-menu'
                theme='dark'
                mode='horizontal'
                selectable={false}
                items={getMenuItems()}
              />
            </Col>
          </Row>
        </Layout.Header>
      </div>
    </div>
  );
};

export default AppHeader;
