import axios from 'axios';
import { UIRoutes } from '../router';

const $api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

function getTimeZoneOffsetToUct() {
  return new Date().getTimezoneOffset() / 60;
}

$api.interceptors.request.use((config: any) => {
  if (config?.url.indexOf('login') === -1) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      '48bceb9d-e1a4-4c77-9d62-9b0e9748c07e'
    )}`;
  }
  config.headers.timezoneOffset = getTimeZoneOffsetToUct();

  return config;
});

$api.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    if (error.request?.responseURL.indexOf('Users') < 0) {
      if (
        (error.response?.status === 401 || error.response?.status === 403) &&
        window.location.href.indexOf(
          `/${UIRoutes.ACCOUNT}/${UIRoutes.LOGIN}`
        ) === -1
      ) {
        window.location.href = `/${UIRoutes.ACCOUNT}/${UIRoutes.LOGIN}`;

        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);
export default $api;
