import { IUser } from '../../../models/Users/user.model';

export interface AuthState {
  isAuth: boolean;
  currentUser: IUser;
}

export enum AuthActionEnum {
  SET_AUTH = 'SET_AUTH',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
}

export interface SetAuthAction {
  type: AuthActionEnum.SET_AUTH;
  payload: boolean;
}

export interface SetCurrentUserAction {
  type: AuthActionEnum.SET_CURRENT_USER;
  payload: IUser;
}

export type AuthAction = SetAuthAction | SetCurrentUserAction;
