import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import { FC, useState } from 'react';

import useHttpGet from '../../hooks/useHttpGet';
import { APIRoutes } from '../../http/apiRoutes';
import { ISystemSettings, getValue } from '../../models/SystemSettings/systemSetting.model';
import { IResultResponse } from '../../models/response/result.model';
import SystemSettingsService from '../../services/SystemSettings/systemSettings.service';
import CommonService from '../../services/common.service';
import { formItemLayout } from '../../services/constants';
import { SettingsTypes } from '../../services/enums';
import Editor from '../Layout/Common/Adapters/Editor';

const SystemSettingsCommonForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const [copyrightContent, setCopyrightContent] = useState<any>(null);

  const [form] = Form.useForm();

  const { fetchedData } = useHttpGet<IResultResponse<ISystemSettings[]>>(
    `${APIRoutes.SYSTEM_SETTINGS}/public`,
    {
      defaultValue: { result: [] as ISystemSettings[] },
      resolve: (response: IResultResponse<ISystemSettings[]>) => {
        form.setFieldsValue({
          systemEmail: getValue(
            response.result,
            SettingsTypes.SystemEmail,
          ),
          systemEmailPassword: getValue(
            response.result,
            SettingsTypes.SystemEmailPassword,
          ),
        });
        setCopyrightContent(
          getValue(response.result, SettingsTypes.Copyright)
        );
      },
      reject: CommonService.showAllErrors,
    }
  );

  const createSystemSetting = (id: SettingsTypes, newValue: string) => {
    const original = fetchedData?.result?.filter(
      (rec: any) => rec.id === id
    )[0];
    original.value = newValue;
    return original;
  };

  const handleSubmitForm = async (values: any) => {
    setLoading(true);
    const systemEmail = createSystemSetting(
      SettingsTypes.SystemEmail,
      values.systemEmail
    );
    await SystemSettingsService.update(systemEmail);

    const systemEmailPassword = createSystemSetting(
      SettingsTypes.SystemEmailPassword,
      values.systemEmailPassword
    );
    await SystemSettingsService.update(systemEmailPassword);

    const copyright = createSystemSetting(
      SettingsTypes.Copyright,
      copyrightContent
    );
    await SystemSettingsService.update(copyright);

    setLoading(false);
    notification.success({ message: `Settings updated` });
  };

  return !fetchedData?.result ? null : (
    <Form
      {...formItemLayout}
      form={form}
      requiredMark={false}
      onFinish={handleSubmitForm}
    >
      <Form.Item label='Enter system E-mail' name='systemEmail'>
        <Input placeholder='systememail@gmail.com' />
      </Form.Item>

      <Form.Item
        label='Enter system E-mail password'
        name='systemEmailPassword'
      >
        <Input.Password
          placeholder='input password'
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>

      <Form.Item label='Enter copyright content (optional)' name='copyright'>
        <Editor
          content={copyrightContent || ''}
          setContent={setCopyrightContent}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
        <Button type='primary' htmlType='submit' loading={loading}>
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SystemSettingsCommonForm;
