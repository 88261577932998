import { Col, Image, Row } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";
import { APIRoutesBase } from "../../../../http/apiRoutes";
import {
  ISystemSettings,
  getValue,
} from "../../../../models/SystemSettings/systemSetting.model";
import { UIRoutes } from "../../../../router";
import { SettingsTypes } from "../../../../services/enums";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";

interface IProps {
  settings: ISystemSettings[];
}

const UpHeader: FC<IProps> = ({ settings }) => {
  return (
    <Row className="bg-white up-header-height">
      {/* xs={24} sm={24} md={20} */}
      <Col className="logo">
        <Link
          to={"/"}
          title={
            settings.length > 0
              ? getValue(settings, SettingsTypes.LogoTitle)
              : ""
          }
        >
          <Image
            src={
              settings.length > 0
                ? `${APIRoutesBase.API_BASE}/${getValue(
                    settings,
                    SettingsTypes.LogoUrl
                  )}`
                : ""
            }
            alt={
              settings.length > 0
                ? getValue(settings, SettingsTypes.LogoTitle)
                : "Logo"
            }
            preview={false}
          />
        </Link>
      </Col>
      <Col flex="auto" className="d-flex jc-e ai-c">
        {localStorage.getItem("app.Authorized") && <ProfileMenu />}
      </Col>
    </Row>
  );
};
export default UpHeader;
