import { AxiosResponse } from 'axios';

import { APIRoutes } from '../../http/apiRoutes';
import { IUser } from '../../models/Users/user.model';
import { IResultResponse } from '../../models/response/result.model';
import RequestsService from '../requests.service';

export default class UsersService {
  static async create(
    user: IUser,
  ): Promise<AxiosResponse<IResultResponse<IUser>>> {
    return RequestsService.postMethod<IResultResponse<IUser>>(
      APIRoutes.USERS,
      user
    );
  }

  static async update(
    user: IUser,
  ): Promise<AxiosResponse<IResultResponse<IUser>>> {
    return RequestsService.putMethod<IResultResponse<IUser>>(
      `${APIRoutes.USERS}/${user.id}`,
      user
    );
  }

  static async updatePassword(
    userId: string,
    password: string,
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.putMethod<IResultResponse<any>>(
      `${APIRoutes.USERS}/${userId}/password/change`,
      {
        newPassword: password,
      }
    );
  }

  static async uploadPhoto(
    id: string,
    file: string,
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.patchMethod<IResultResponse<any>>(
      `${APIRoutes.USERS}/${id}/photo`,
      file
    );
  }

  static async changePassword(
    nPassword: string,
    cPassword: string,
  ): Promise<AxiosResponse<IResultResponse<IUser>>> {
    return RequestsService.putMethod<IResultResponse<any>>(
      `${APIRoutes.USERS}/password/change`,
      {
        newPassword: nPassword,
        currentPassword: cPassword,
      }
    );
  }

  static async changePhoto(
    file: string,
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.patchMethod<IResultResponse<any>>(
      `${APIRoutes.USERS}/photo`,
      file
    );
  }
}
