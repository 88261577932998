import { Badge, Tooltip } from 'antd';
import { Link, NavLink } from 'react-router-dom';

// for label of menu items
export const getNavLink = (title: any, url: string, target?: string) => (
  <NavLink
    className={(navData) => (navData.isActive ? 'active' : '')}
    to={url}
    target={target}
  >
    {title}
  </NavLink>
);

// for label of menu items
export const getLink = (title: any, url: string, target?: string) => (
  <Link
    to={url}
    target={target}
  >
    {title}
  </Link>
);

// for label of conrol panel longest menu items
export const getTitlWithToolTip = (title: any) => (
  <Tooltip title={title} placement='right'>
    {title}
  </Tooltip>
);

// for label of conrol panel longest menu items
export const getNavLinkWithToolTip = (title: string, url: string, target?: string) => (
  <Tooltip title={title} placement='right'>
    {getLink(title, url, target)}
  </Tooltip>
);

// for profile menu
export const getTitleWithBadge = (title: any, count: number) => (
  <>
    {title}
    <Badge count={count} size='small' offset={[3, -15]} />
  </>
);
