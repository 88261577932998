import { Button, Form, FormInstance, Input, Tag, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ControlPanelNav } from '../../areas/controlPanel/config/navigation';
import { IEmailTemplate } from '../../models/email-template.model';
import EmailTemplatesService from '../../services/EmailTemplates/emailTemplates.service';
import CommonService from '../../services/common.service';
import { formItemLayout } from '../../services/constants';
import Editor from '../Layout/Common/Adapters/Editor';

interface IProps {
  data: IEmailTemplate;
  form: FormInstance;
}

const EmailTemplateForm: FC<IProps> = ({ data, form }) => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    setContent(data?.contentHtml);
  }, [data]);

  const update = async (values: IEmailTemplate) => {
    setLoading(true);

    try {
      await EmailTemplatesService.update({
        ...data,
        ...values,
        contentHtml: content,
      });

      notification.success({ message: 'Template has been updated' });

      setLoading(false);

      navigate(ControlPanelNav.EMAIL_TEMPLATES);
    } catch (errors: any) {
      setLoading(false);

      CommonService.showAllErrors(errors);
    }
  };

  const tags = () => {
    return data.keys.split(', ').map((i) => <Tag key={i}>{i}</Tag>);
  };

  const handleSubmitForm = async (values: any) => {
    await update(values);
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      requiredMark={false}
      onFinish={handleSubmitForm}
    >
      <Form.Item
        label='Subject'
        name='subject'
        rules={[{ required: true, message: 'Please input subject!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Keys'
        rules={[{ required: true, message: 'Please input keys!' }]}
      >
        {data?.keys?.length > 0 ? tags() : null}
      </Form.Item>
      <Form.Item label='Content'>
        <Editor content={content || ''} setContent={setContent} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
        <Button type='primary' htmlType='submit' loading={loading}>
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmailTemplateForm;
