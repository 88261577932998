// import { Permissions } from 'constants/users/permissions';
import {
  FileOutlined,
  FormatPainterOutlined,
  LayoutOutlined,
  PictureOutlined,
  PieChartOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import React from "react";
import { getLink } from "../../../services/menuCreating";
import { ControlPanelNav } from "./navigation";

const getLeftMenuData = (): ItemType[] => {
  return [
    {
      key: "0",
      icon: React.createElement(PieChartOutlined),
      label: getLink("Dashboard", ControlPanelNav.DASHBOARD),
    },
    {
      key: "2",
      icon: React.createElement(UserOutlined),
      label: getLink("Users", ControlPanelNav.USERS),
    },
    {
      key: "5",
      icon: React.createElement(LayoutOutlined),
      label: getLink("Content Pages", ControlPanelNav.MAGIC_PAGES),
    },
    {
      key: "11",
      icon: React.createElement(FileOutlined),
      label: getLink("FileBank", ControlPanelNav.FILE_BANK),
    },
    {
      key: "12",
      icon: React.createElement(PictureOutlined),
      label: getLink("ImageBank", ControlPanelNav.IMAGE_BANK),
    },
    {
      key: "6",
      icon: React.createElement(SettingOutlined),
      label: getLink("System Settings", ControlPanelNav.SYSTEM_SETTINGS),
    },
    {
      key: "7",
      icon: React.createElement(FormatPainterOutlined),
      label: getLink("Email Templates", ControlPanelNav.EMAIL_TEMPLATES),
    },
  ];
};

export default getLeftMenuData;
