export enum APIRoutesVersions {
  V1 = "/v1",
}

export const APIRoutesBase = {
  API_BASE: process.env.REACT_APP_API_URL,

  AUTH: `${APIRoutesVersions.V1}/Auth`,

  DASHBOARD: `${APIRoutesVersions.V1}/Dashboard`,
  EMAIL_TEMPLATES: `${APIRoutesVersions.V1}/EmailTemplates`,

  FAQ: `${APIRoutesVersions.V1}/Faq`,
  FAQ_TOPICS: `${APIRoutesVersions.V1}/Faq/topics`,

  MAGIC_PAGES: `${APIRoutesVersions.V1}/MagicPages`,
  MAGIC_PAGES_TYPE: `${APIRoutesVersions.V1}/MagicPages/type`,
  MAGIC_PAGES_HISTORY: `${APIRoutesVersions.V1}/MagicPagesHistory`,
  MAGIC_FILES: `${APIRoutesVersions.V1}/MagicFiles`,

  MAGIC_PAGES_Content_Items: `${APIRoutesVersions.V1}/MagicContentItems`,
  MAGIC_PAGES_Content_Items_ForPage: `${APIRoutesVersions.V1}/MagicContentItems/forPage`,

  NOTIFICATIONS: `${APIRoutesVersions.V1}/Notifications`,
  NOTIFICATIONS_COUNT_UNREAD: `${APIRoutesVersions.V1}/Notifications/countUnread`,
  NOTIFICATIONS_READ: `${APIRoutesVersions.V1}/Notifications/read`,
  NOTIFICATIONS_ARCHIVE: `${APIRoutesVersions.V1}/Notifications/archive`,

  SYSTEM_SETTINGS: `${APIRoutesVersions.V1}/SystemSettings`,
  USERS: `${APIRoutesVersions.V1}/Users`,
  USERS_MY: `${APIRoutesVersions.V1}/Users/my`,
};

export const APIRoutes = {
  ...APIRoutesBase,
  DASHBOARD_INSTRUMENT_MOVEMENTS: `${APIRoutesBase.DASHBOARD}/instrumentMovements`,

  AUTH_LOGIN: `${APIRoutesBase.AUTH}/login`,
  AUTH_FORGOT_PASSWORD_REQUEST: `${APIRoutesBase.AUTH}/forgotPassword/request`,
  AUTH_FORGOT_PASSWORD_CHANGE: `${APIRoutesBase.AUTH}/forgotPassword/change`,

  MAGIC_PAGES_HISTORY_FOR_PAGE: `${APIRoutesBase.MAGIC_PAGES_HISTORY}/forPage`,
};
