import { Col, Row } from "antd";
import { FC } from "react";

const DashboardStatistic: FC = () => {
  return (
    <Row>
      <Col className="w-100 d-flex jc-sb"></Col>
    </Row>
  );
};

export default DashboardStatistic;
