import { Button, Form, FormInstance, Input, Upload, notification } from "antd";
import { FC, useEffect, useState } from "react";
import { APIRoutes } from "../../http/apiRoutes";
import {
  CloseCircleOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import CommonService from "../../services/common.service";
import { IMagicPageItem } from "../../models/MagicPages/magicPage.model";
import MagicPagesContentItems from "../../services/MagicPages/magicPagesContentItems.service";
import MagicFilesService from "../../services/MagicFiles/magicFiles.service";
import { formItemLayout } from "../../services/constants";
import Editor from "../Layout/Common/Adapters/Editor";
import { MagicFileTypes } from "../../services/enums";

const { Dragger } = Upload;

interface IProps {
  data: IMagicPageItem;
  form: FormInstance;
}

const MagicPageContentItemForm: FC<IProps> = ({ data, form }) => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [changedImage, setChangedImage] = useState(null);
  const [changedImageBase64, setChangedImageBase64] = useState<any>(null);

  useEffect(() => {
    setContent(data?.content);
  }, [data]);

  const create = async (values: IMagicPageItem) => {
    setLoading(true);

    try {
      await MagicPagesContentItems.create({ ...values, content });

      notification.success({ message: `Page item has been created` });

      setLoading(false);
    } catch (errors: any) {
      setLoading(false);

      CommonService.showAllErrors(errors);
    }
  };

  const update = async (values: IMagicPageItem) => {
    setLoading(true);
    try {
      await MagicPagesContentItems.update({ ...data, ...values, content });

      notification.success({ message: `Page item has been updated` });

      setLoading(false);
    } catch (errors: any) {
      setLoading(false);

      CommonService.showAllErrors(errors);
    }
  };

  const handleImageUpload = async () => {
    if (changedImage) {
      setLoading(true);

      const name = form.getFieldValue("title");

      try {
        const formData = CommonService.prepareFormData(changedImage);
        formData.append("name", name);
        formData.append("type", MagicFileTypes.Image);

        const uploadResult = await MagicFilesService.upload(formData);

        if (uploadResult.status === 200) {
          form.setFieldsValue({
            image: uploadResult.data.result.path,
          });
          checkFinishHandler("uploaded");
        } else {
          notification.error({ message: "Image isn't uploaded" });
        }
        setLoading(false);
      } catch (errors) {
        checkErrorHandler(errors);
      }
    }
  };

  const checkFinishHandler = (message: string) => {
    notification.success({ message: `The image has been ${message}` });

    setLoading(false);
  };

  const checkErrorHandler = (errors: any) => {
    setLoading(false);

    CommonService.showAllErrors(errors);
  };

  const handleDraggerBeforeUpload = (image: File) => {
    if (image.size / 1024 / 1024 > 10) {
      notification.error({ message: "Image must be smaller than 10MB!" });
    }

    return false;
  };

  const handleChangeImage = (info: any) => {
    setChangedImage(info.file);

    const reader = new FileReader();
    reader.addEventListener("load", () => setChangedImageBase64(reader.result));
    reader.readAsDataURL(info.file);
  };

  const handleSubmitForm = async (values: any) => {
    if (data?.id) {
      await update(values);
    } else {
      await create(values);
    }
  };

  const uploadButton = (
    <div className="fs-22">
      <p className="ant-upload-drag-icon">
        {loading ? <LoadingOutlined /> : <UploadOutlined />}
      </p>
      <p className="ant-upload-text">
        Click or drag image to this area to upload
      </p>
    </div>
  );

  return (
    <Form
      {...formItemLayout}
      form={form}
      requiredMark={true}
      onFinish={handleSubmitForm}
    >
      <Form.Item label={null} name="magicPageId">
        <Input hidden />
      </Form.Item>

      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: "Please input title!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: "Please input description!" }]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item label="Image uploader" name="uploader">
        <Dragger
          accept="image/png,image/jpeg"
          listType="picture-card"
          showUploadList={false}
          beforeUpload={handleDraggerBeforeUpload}
          onChange={handleChangeImage}
        >
          {changedImageBase64 ? (
            <div>
              <img
                src={changedImageBase64}
                alt="Logo url"
                style={{ maxHeight: 102, maxWidth: 102 }}
              />
            </div>
          ) : (
            uploadButton
          )}
        </Dragger>

        {changedImage && (
          <>
            <CloseCircleOutlined
              className="upload-close"
              title="Delete"
              onClick={() => {
                setChangedImage(null);
                setChangedImageBase64(null);
              }}
            />

            <UploadOutlined
              className="upload-close"
              title="Upload"
              onClick={handleImageUpload}
            />
          </>
        )}

        {data?.image && (
          <img
            src={`${APIRoutes.API_BASE}/${data.image}`}
            style={{ maxWidth: 300 }}
          />
        )}
      </Form.Item>

      <Form.Item label="Image" name="image">
        <Input />
      </Form.Item>

      <Form.Item label="Background" name="background">
        <Input />
      </Form.Item>

      <Form.Item label="CustomCss" name="customCss">
        <Input />
      </Form.Item>

      <Form.Item label="Icon" name="icon">
        <Input />
      </Form.Item>

      <Form.Item label="Link" name="link">
        <Input />
      </Form.Item>

      <Form.Item label="Sort Number" name="sortNumber">
        <Input />
      </Form.Item>

      <Form.Item label="Content">
        <Editor content={content || ""} setContent={setContent} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MagicPageContentItemForm;
