import { IUser } from '../../../models/Users/user.model';
import { AuthActionEnum, SetAuthAction, SetCurrentUserAction } from './types';

export const AuthActionCreators = {
  setIsAuth: (payload: boolean): SetAuthAction => ({
    type: AuthActionEnum.SET_AUTH,
    payload,
  }),

  setCurrentUser: (payload: IUser): SetCurrentUserAction => ({
    type: AuthActionEnum.SET_CURRENT_USER,
    payload,
  }),
};
