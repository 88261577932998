import { FC } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import PublicAndLPPageTemplate from "../../../components/Layout/Common/PublicAndLPPageTemplate";
import useHttpGet from "../../../hooks/useHttpGet";
import { APIRoutes } from "../../../http/apiRoutes";
import { IMagicPageItem } from "../../../models/MagicPages/magicPage.model";
import { IResultResponse } from "../../../models/response/result.model";
import { REACT_APP_SITE_TITLE } from "../../../services/constants";

const PageContentItem: FC = () => {
  const id: string = useParams().id ?? "";

  const { fetchedData, loading } = useHttpGet<IResultResponse<IMagicPageItem>>(
    `${APIRoutes.MAGIC_PAGES_Content_Items}/${id}`,
    {
      dependencies: [id],
      defaultValue: { result: {} as IMagicPageItem },
    }
  );

  return (
    <PublicAndLPPageTemplate
      loading={loading}
      title={fetchedData.result?.title}
      content={fetchedData.result?.content}
    >
      <Helmet>
        <title>{`${fetchedData.result?.title} | ${REACT_APP_SITE_TITLE}`}</title>
        <meta name="description" content={fetchedData.result?.description} />
      </Helmet>
    </PublicAndLPPageTemplate>
  );
};

export default PageContentItem;
