import React, { FC } from 'react';
import List from '../../../../components/EmailTemplates/List';

import CPPageTemplate from '../../../../components/Layout/Common/CPPageTemplate';

const EmailTemplatesList: FC = () => {
  return (
    <CPPageTemplate title={'Email Templates'}>
      <List />
    </CPPageTemplate>
  );
};

export default EmailTemplatesList;
