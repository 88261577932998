import FaqContentItems from "../../../components/FaqContentItems/Index";
import InquiryForm from "../../../components/InquiryForm/Form";
import ProjectsContentItems from "../../../components/ProjectContentItems/Index";
import { MagicPagesTypes } from "../../../services/enums";

export const PageContentItems = (type: MagicPagesTypes): React.ReactNode => {
  switch (type) {
    case MagicPagesTypes.Contacts:
      return <InquiryForm />;

    case MagicPagesTypes.Projects:
      return <ProjectsContentItems />;

    case MagicPagesTypes.Faq:
      return <FaqContentItems />;
  }
  return null;
};
