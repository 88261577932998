import React, { FC } from 'react';
import { Form, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import useHttpGet from '../../../../hooks/useHttpGet';
import { APIRoutes } from '../../../../http/apiRoutes';
import { IResultResponse } from '../../../../models/response/result.model';
import CPPageTemplate from '../../../../components/Layout/Common/CPPageTemplate';
import UserForm from '../../../../components/Users/Form';
import { IUser } from '../../../../models/Users/user.model';

const UserEdit: FC = () => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const { fetchedData, loading } = useHttpGet<IResultResponse<IUser>>(
    `${APIRoutes.USERS}/${id}`,
    {
      defaultValue: { result: {} as any },
      condition: !!id,
      resolve: (response: IResultResponse<IUser>) =>
        form.setFieldsValue(response.result),
    }
  );

  return (
    <CPPageTemplate
      title={
        !!id
          ? `Update '${fetchedData.result?.firstName} ${fetchedData.result?.lastName}'`
          : 'Create user'
      }
    >
      <Spin spinning={loading}>
        <UserForm data={fetchedData.result} form={form} />
      </Spin>
    </CPPageTemplate>
  );
};

export default UserEdit;
