import { FC } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import CPPageTemplate from '../../../../components/Layout/Common/CPPageTemplate';
import List from '../../../../components/Users/List';
import { ControlPanelNav } from '../../config/navigation';

const UsersList: FC = () => {
  const navigate = useNavigate();

  const createHandler = () => {
    navigate(ControlPanelNav.USER_CREATE);
  };

  return (
    <CPPageTemplate
      title={'Users'}
      extra={<Button onClick={createHandler}>CREATE</Button>}
    >
      <List />
    </CPPageTemplate>
  );
};

export default UsersList;
