import { FC } from "react";
import { Col, Layout, Menu, Row } from "antd";
import { InstagramOutlined } from "@ant-design/icons";
import {
  ISystemSettings,
  getValue,
} from "../../../../../models/SystemSettings/systemSetting.model";
import { MagicPagesTypes, SettingsTypes } from "../../../../../services/enums";

interface IProps {
  settings: ISystemSettings[];
  menuTheme?: "dark" | "light";
}

export interface MenuItem {
  name: string;
  id: MagicPagesTypes;
  segment?: string;
  url?: string;
}

const AppFooter: FC<IProps> = ({ settings, menuTheme = "dark" }) => {
  return (
    <Layout.Footer
      className={`p-2 ${
        menuTheme === "light" ? "ant-layout-footer-light" : ""
      }`}
    >
      <Row className="mt-2">
        <Col xs={24} sm={24} md={24}>
          <div
            className={`align-items-center pl-1 text-bg-${menuTheme}`}
            dangerouslySetInnerHTML={{
              __html: getValue(settings, SettingsTypes.Copyright),
            }}
          />
        </Col>
      </Row>
    </Layout.Footer>
  );
};

export default AppFooter;
