import { FC } from 'react';
import { Tabs } from 'antd';

import CPPageTemplate from '../../../../components/Layout/Common/CPPageTemplate';
import SystemSettingsHeaderForm from '../../../../components/SystemSettings/headerForm';
import SystemSettingsHomeScreenForm from '../../../../components/SystemSettings/homeScreenForm';
import SystemSettingsCommonForm from '../../../../components/SystemSettings/commonForm';

const SystemSettings2Edit: FC = () => {
  const { TabPane } = Tabs;

  return (
    <CPPageTemplate title={'Update system settings'}>
      <Tabs defaultActiveKey='1'>
        <TabPane tab='Header' key='1'>
          <SystemSettingsHeaderForm />
        </TabPane>

        <TabPane tab='Home Screen' key='2'>
          <SystemSettingsHomeScreenForm />
        </TabPane>

        <TabPane tab='Common' key='3'>
          <SystemSettingsCommonForm />
        </TabPane>
      </Tabs>
    </CPPageTemplate>
  );
};

export default SystemSettings2Edit;
