import { FC } from 'react';
import CPPageTemplate from '../../../../components/Layout/Common/CPPageTemplate';
import DashboardStatistic from '../../../../components/Dashboard/DashboardStatistic';

const Dashboard: FC = () => {
  return (
    <CPPageTemplate
      title='Dashboard & Statistics'
    >
      <DashboardStatistic />
    </CPPageTemplate>
  );
};

export default Dashboard;
